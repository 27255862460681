import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const reportFiscalYearList = vm.reportFiscalYearList
        const reportCropInfoList = vm.reportCropInfoList
        const rowItemLength = 2 + (reportFiscalYearList.length * 6)
        const perPageRow = 20
        if (reportFiscalYearList.length) {
          const allRows = []
          const heading1 = [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
            { text: vm.$t('ministryDashboard.cropName'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 }
          ]
          reportFiscalYearList.forEach(item => {
            heading1.push(
              { text: vm.$t('ministryDashboard.pro') + ' ' + (vm.currentLocale === 'en' ? item.text_en : item.text_bn), style: 'th', alignment: 'center', bold: true, colSpan: 3 },
              {},
              {},
              { text: vm.$t('ministryDashboard.achievement') + ' ' + (vm.currentLocale === 'en' ? item.text_en : item.text_bn), style: 'th', alignment: 'center', bold: true, colSpan: 3 },
              {},
              {}
            )
          })
          const heading2 = [{}, {}]
          reportFiscalYearList.forEach(item => {
            heading2.push(
              { text: vm.$t('ministryDashboard.abadi_jomi'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.productions'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.yield'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.abadi_jomi'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.productions'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.yield'), style: 'th', alignment: 'center', bold: true }
            )
          })
          const heading3 = [{}, {}]
          reportFiscalYearList.forEach(item => {
            heading3.push(
              { text: vm.$t('ministryDashboard.lakh_hector'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.lakh_mt'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.ton_hector'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.lakh_hector'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.lakh_mt'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('ministryDashboard.ton_hector'), style: 'th', alignment: 'center', bold: true }
            )
          })
          allRows.push(heading1, heading2, heading3)
          if (reportCropInfoList.length) {
            let totalRows = 0
            // reportCropInfoList loop start
            reportCropInfoList.forEach((cropType, cropTypeIndex) => {
              allRows.push([
                { text: vm.getCropTypeName(cropType.crop_type_id), style: 'td', alignment: 'center', colSpan: 2 + (reportFiscalYearList.length * 6), headlineLevel: ((totalRows >= perPageRow) ? 1 : null) }
              ])
              if (totalRows >= perPageRow) {
                totalRows = 0
              }
              totalRows++
              // totalPageRows++
              // Sub type list loop start
              cropType.sub_type_list.forEach((subType, subTypeIndex) => {
                // crop list loop start
                subType.crop_name_list.forEach((cropName, cropNameIndex) => {
                // if category list exists
                  if (cropName.category_list.length > 0) {
                    // category list loop start
                    cropName.category_list.forEach((category, categoryIndex) => {
                      // if cropNameIndex equal to 0
                      if (cropNameIndex === 0) {
                        // if categoryIndex equal to 0
                        if (categoryIndex === 0) {
                          if (totalRows >= perPageRow) {
                            allRows.push([
                              { text: '  ', style: 'tdNone', alignment: 'center', colSpan: 2 + (reportFiscalYearList.length * 6), headlineLevel: 1 }
                            ])
                            totalRows = 0
                          }
                          const rowItem = [
                            { text: vm.$n(subTypeIndex + 1) + '.' + vm.$n(cropNameIndex + 1), style: 'td', alignment: 'center', rowSpan: (cropName.category_list.length ? cropName.category_list.length + 1 : null) },
                            { text: vm.getCategoryName(category.category_id), style: 'td', alignment: 'center' }
                          ]
                          // fiscal year loop start
                          reportFiscalYearList.forEach((item, index) => {
                            // if subTypeIndex is equal to 0
                            if (subTypeIndex === 0) {
                              if ((!vm.isFiscalYearExists(item.value)) || (!vm.isCropTypeExists(item.value, cropType.crop_type_id))) {
                                rowItem.push(
                                  { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', rowSpan: cropType.total_row, colSpan: 6, margin: [0, 10 * cropType.total_row, 0, 0] },
                                  {},
                                  {},
                                  {},
                                  {},
                                  {}
                                )
                              } else {
                                if (!vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                                  rowItem.push(
                                    { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', rowSpan: subType.total_row, colSpan: 6, margin: [0, 10 * subType.total_row, 0, 0] },
                                    {},
                                    {},
                                    {},
                                    {},
                                    {}
                                  )
                                } else {
                                  rowItem.push(
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output'), style: 'td', alignment: 'center' }
                                  )
                                }
                              }
                              // if subTypeIndex is grater than 0
                            } else {
                              if (vm.isFiscalYearExists(item.value)) {
                                if (!vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                                  rowItem.push(
                                    { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', rowSpan: subType.total_row, colSpan: 6, margin: [0, 10 * subType.total_row, 0, 0] },
                                    {},
                                    {},
                                    {},
                                    {},
                                    {}
                                  )
                                } else {
                                  rowItem.push(
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement'), style: 'td', alignment: 'center' },
                                    { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output'), style: 'td', alignment: 'center' }
                                  )
                                }
                              } else {
                                rowItem.push({}, {}, {}, {}, {}, {})
                              }
                            }
                          })
                          // fiscal year loop end

                          const reqRowLength = parseInt(rowItemLength) - parseInt(rowItem.length)
                          if (reqRowLength !== 0) {
                            let j = 1
                            while (j <= reqRowLength) {
                              rowItem.push({})
                              j++
                            }
                          }
                          allRows.push(rowItem)
                          totalRows++
                          // if categoryIndex greater than 0
                        } else {
                          const rowItem = [
                            { text: '' },
                            { text: vm.getCategoryName(category.category_id), style: 'td', alignment: 'center' }
                          ]
                          // fiscal year loop start
                          reportFiscalYearList.forEach((item, index) => {
                            if (vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                              rowItem.push(
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output'), style: 'td', alignment: 'center' }
                              )
                            } else {
                              rowItem.push({}, {}, {}, {}, {}, {})
                            }
                          })
                          // fiscal year loop end

                          const reqRowLength = parseInt(rowItemLength) - parseInt(rowItem.length)
                          if (reqRowLength !== 0) {
                            let j = 1
                            while (j <= reqRowLength) {
                              rowItem.push({})
                              j++
                            }
                          }

                          allRows.push(rowItem)
                          totalRows++
                        }

                        // if cropNameIndex greater than 0
                      } else {
                        // if categoryIndex equal to 0
                        if (categoryIndex === 0) {
                          if (totalRows >= perPageRow) {
                            allRows.push([
                              { text: '  ', style: 'tdNone', alignment: 'center', colSpan: 2 + (reportFiscalYearList.length * 6), headlineLevel: 1 }
                            ])
                            totalRows = 0
                          }
                          const rowItem = [
                            { text: vm.$n(subTypeIndex + 1) + '.' + vm.$n(cropNameIndex + 1), style: 'td', alignment: 'center', rowSpan: (cropName.category_list.length ? cropName.category_list.length + 1 : null) },
                            // { text: vm.$n(subTypeIndex + 1) + '.' + vm.$n(cropNameIndex + 1), style: 'td', alignment: 'center' },
                            { text: vm.getCategoryName(category.category_id), style: 'td', alignment: 'center' }
                          ]
                          // fiscal year loop start
                          reportFiscalYearList.forEach((item, index) => {
                            if (vm.isFiscalYearExists(item.value)) {
                              if (!vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                                rowItem.push(
                                  { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', rowSpan: subType.total_row, colSpan: 6, margin: [0, 10 * subType.total_row, 0, 0] },
                                  {},
                                  {},
                                  {},
                                  {},
                                  {}
                                )
                              } else {
                                rowItem.push(
                                  { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target'), style: 'td', alignment: 'center' },
                                  { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target'), style: 'td', alignment: 'center' },
                                  { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output'), style: 'td', alignment: 'center' },
                                  { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement'), style: 'td', alignment: 'center' },
                                  { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement'), style: 'td', alignment: 'center' },
                                  { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output'), style: 'td', alignment: 'center' }
                                )
                              }
                            } else {
                              rowItem.push({}, {}, {}, {}, {}, {})
                            }
                          })
                          // fiscal year loop end
                          const reqRowLength = parseInt(rowItemLength) - parseInt(rowItem.length)
                          if (reqRowLength !== 0) {
                            let j = 1
                            while (j <= reqRowLength) {
                              rowItem.push({})
                              j++
                            }
                          }
                          allRows.push(rowItem)
                          totalRows++
                          // if categoryIndex greater than 0
                        } else {
                          const rowItem = [
                            { text: '' },
                            { text: vm.getCategoryName(category.category_id), style: 'td', alignment: 'center' }
                          ]
                          // fiscal year loop start
                          reportFiscalYearList.forEach((item, index) => {
                            if (vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                              rowItem.push(
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output'), style: 'td', alignment: 'center' }
                              )
                            } else {
                              rowItem.push({}, {}, {}, {}, {}, {})
                            }
                          })
                          // fiscal year loop end
                          const reqRowLength = parseInt(rowItemLength) - parseInt(rowItem.length)
                          if (reqRowLength !== 0) {
                            let j = 1
                            while (j <= reqRowLength) {
                              rowItem.push({})
                              j++
                            }
                          }
                          allRows.push(rowItem)
                          totalRows++
                        }
                      }
                    })
                    // category list loop end

                    // crop name total
                    const rowItem = [
                      { text: '' },
                      { text: vm.$t('globalTrans.total') + '  ' + vm.getSpecificCropName(cropName.crop_name_id) + ' :', style: 'td', alignment: 'center' }
                    ]
                    // fiscal year loop start
                    reportFiscalYearList.forEach((item, index) => {
                      if (vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                        rowItem.push(
                          {
                            text: (vm.isCropNameExists(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && (vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'total_land_target', false) > 0) ? vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'total_land_target') : ''),
                            style: 'td',
                            alignment: 'center'
                          },
                          {
                            text: (vm.isCropNameExists(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'total_production_target', false) > 0) ? vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'total_production_target') : '',
                            style: 'td',
                            alignment: 'center'
                          },
                          {
                            text: (vm.isCropNameExists(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'target_output', false) > 0) ? vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'target_output') : '',
                            style: 'td',
                            alignment: 'center'
                          },
                          {
                            text: (vm.isCropNameExists(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'total_land_achievement', false) > 0) ? vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'total_land_achievement') : '',
                            style: 'td',
                            alignment: 'center'
                          },
                          {
                            text: (vm.isCropNameExists(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'total_production_achievement', false) > 0) ? vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'total_production_achievement') : '',
                            style: 'td',
                            alignment: 'center'
                          },
                          {
                            text: (vm.isCropNameExists(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'achievement_output', false) > 0) ? vm.getCategoryTotal(item.value,
                              cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                              'achievement_output') : '',
                            style: 'td',
                            alignment: 'center'
                          }
                        )
                      } else {
                        rowItem.push({}, {}, {}, {}, {}, {})
                      }
                    })
                    // fiscal year loop end

                    const reqRowLength = parseInt(rowItemLength) - parseInt(rowItem.length)
                    if (reqRowLength !== 0) {
                      let j = 1
                      while (j <= reqRowLength) {
                        rowItem.push({})
                        j++
                      }
                    }
                    allRows.push(rowItem)
                    totalRows++
                    // crop name total end

                    // if category list not exists
                  } else {
                    if (totalRows >= perPageRow) {
                      allRows.push([
                        { text: '  ', style: 'tdNone', alignment: 'center', colSpan: 2 + (reportFiscalYearList.length * 6), headlineLevel: 1 }
                      ])
                      totalRows = 0
                    }
                    const rowItem = [
                      { text: vm.$n(subTypeIndex + 1) + '.' + vm.$n(cropNameIndex + 1), style: 'td', alignment: 'center' },
                      { text: vm.getSpecificCropName(cropName.crop_name_id), style: 'td', alignment: 'center' }
                    ]

                    // fiscal year list loop start
                    reportFiscalYearList.forEach((item, index) => {
                      // if subTypeIndex is equal to 0
                      if (subTypeIndex === 0) {
                        // if cropNameIndex is equal to 0
                        if (cropNameIndex === 0) {
                          if ((!vm.isFiscalYearExists(item.value)) || (!vm.isCropTypeExists(item.value, cropType.crop_type_id))) {
                            rowItem.push(
                              { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', rowSpan: cropType.total_row, colSpan: 6, margin: [0, 10 * cropType.total_row, 0, 0] },
                              {},
                              {},
                              {},
                              {},
                              {}
                            )
                          } else {
                            if (!vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                              rowItem.push(
                                { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', rowSpan: subType.total_row, colSpan: 6, margin: [0, 10 * subType.total_row, 0, 0] },
                                {},
                                {},
                                {},
                                {},
                                {}
                              )
                            } else {
                              rowItem.push(
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_target'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_target'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'target_output'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_achievement'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_achievement'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'achievement_output'), style: 'td', alignment: 'center' }
                              )
                            }
                          }
                          // if cropNameIndex is greater than 0
                        } else {
                          if (vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                            rowItem.push(
                              { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_target'), style: 'td', alignment: 'center' },
                              { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_target'), style: 'td', alignment: 'center' },
                              { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'target_output'), style: 'td', alignment: 'center' },
                              { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_achievement'), style: 'td', alignment: 'center' },
                              { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_achievement'), style: 'td', alignment: 'center' },
                              { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'achievement_output'), style: 'td', alignment: 'center' }
                            )
                          } else {
                            rowItem.push({}, {}, {}, {}, {}, {})
                          }
                        }
                        // if subTypeIndex is greater than 0
                      } else {
                        // if cropNameIndex equal to 0
                        if (cropNameIndex === 0) {
                          if (vm.isFiscalYearExists(item.value)) {
                            if (!vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                              rowItem.push(
                                { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', rowSpan: subType.total_row, colSpan: 6, margin: [0, 10 * subType.total_row, 0, 0] },
                                {},
                                {},
                                {},
                                {},
                                {}
                              )
                            } else {
                              rowItem.push(
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_target'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_target'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'target_output'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_achievement'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_achievement'), style: 'td', alignment: 'center' },
                                { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'achievement_output'), style: 'td', alignment: 'center' }
                              )
                            }
                          } else {
                            rowItem.push({}, {}, {}, {}, {}, {})
                          }
                        // if cropNameIndex greater than 0
                        } else {
                          rowItem.push(
                            { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_target'), style: 'td', alignment: 'center' },
                            { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_target'), style: 'td', alignment: 'center' },
                            { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'target_output'), style: 'td', alignment: 'center' },
                            { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_achievement'), style: 'td', alignment: 'center' },
                            { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_achievement'), style: 'td', alignment: 'center' },
                            { text: vm.getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'achievement_output'), style: 'td', alignment: 'center' }
                          )
                        }
                      }
                    })
                    // fiscal year list loop end
                    const reqRowLength = parseInt(rowItemLength) - parseInt(rowItem.length)
                    if (reqRowLength !== 0) {
                      let j = 1
                      while (j <= reqRowLength) {
                        rowItem.push({})
                        j++
                      }
                    }
                    allRows.push(rowItem)
                    totalRows++
                  }
                })
                // crop list loop end

                // Sub type total
                if (subType.sub_type_id > 0) {
                  if (totalRows >= perPageRow) {
                    allRows.push([
                      { text: '  ', style: 'tdNone', alignment: 'center', colSpan: 2 + (reportFiscalYearList.length * 6), headlineLevel: 1 }
                    ])
                    totalRows = 0
                  }
                  const rowItem = [
                    { text: vm.$t('globalTrans.total') + '  ' + vm.getCropSubTypeName(subType.sub_type_id) + ' :', style: 'td', alignment: 'center', colSpan: 2 },
                    {}
                  ]
                  // fiscal year list loop start
                  reportFiscalYearList.forEach((item, index) => {
                    if (vm.isFiscalYearExists(item.value) && vm.isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)) {
                      rowItem.push(
                        {
                          text: vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_land_target', false) > 0 ? vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_land_target') : '',
                          style: 'td',
                          alignment: 'center'
                        },
                        {
                          text: vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_production_target', false) > 0 ? vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_production_target') : '',
                          style: 'td',
                          alignment: 'center'
                        },
                        {
                          text: vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'target_output', false) > 0 ? vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'target_output') : '',
                          style: 'td',
                          alignment: 'center'
                        },
                        {
                          text: vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_land_achievement', false) > 0 ? vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_land_achievement') : '',
                          style: 'td',
                          alignment: 'center'
                        },
                        {
                          text: vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_production_achievement', false) > 0 ? vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_production_achievement') : '',
                          style: 'td',
                          alignment: 'center'
                        },
                        {
                          text: vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'achievement_output', false) > 0 ? vm.getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'achievement_output') : '',
                          style: 'td',
                          alignment: 'center'
                        }
                      )
                    } else {
                      rowItem.push({}, {}, {}, {}, {}, {})
                    }
                  })
                  // fiscal year list loop end
                  allRows.push(rowItem)
                  totalRows++
                }
              })
              // Sub type loop end

              if (totalRows >= perPageRow) {
                allRows.push([
                  { text: '  ', style: 'tdNone', alignment: 'center', colSpan: 2 + (reportFiscalYearList.length * 6), headlineLevel: 1 }
                ])
                totalRows = 0
              }

              // Crop Type Total
              const rowItem = [
                { text: vm.$t('globalTrans.total') + '  ' + vm.getCropTypeName(cropType.crop_type_id) + ' :', style: 'td', alignment: 'center', colSpan: 2 },
                {}
              ]
              // fiscal year list loop start
              reportFiscalYearList.forEach((item, index) => {
                if (vm.isFiscalYearExists(item.value) && vm.isCropTypeExists(item.value, cropType.crop_type_id)) {
                  rowItem.push(
                    {
                      text: vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'total_land_target', false) > 0 ? vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'total_land_target') : '',
                      style: 'td',
                      alignment: 'center'
                    },
                    {
                      text: vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'total_production_target', false) > 0 ? vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'total_production_target') : '',
                      style: 'td',
                      alignment: 'center'
                    },
                    {
                      text: vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'target_output', false) > 0 ? vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'target_output') : '',
                      style: 'td',
                      alignment: 'center'
                    },
                    {
                      text: vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'total_land_achievement', false) > 0 ? vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'total_land_achievement') : '',
                      style: 'td',
                      alignment: 'center'
                    },
                    {
                      text: vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'total_production_achievement', false) > 0 ? vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'total_production_achievement') : '',
                      style: 'td',
                      alignment: 'center'
                    },
                    {
                      text: vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'achievement_output', false) > 0 ? vm.getSubTypeTotal(item.value, cropType.crop_type_id, 'achievement_output') : '',
                      style: 'td',
                      alignment: 'center'
                    }
                  )
                } else {
                  rowItem.push({}, {}, {}, {}, {}, {})
                }
              })
              // fiscal year list loop end
              allRows.push(rowItem)
              totalRows++
            })
            // reportCropInfoList loop end
          } else {
            allRows.push([
              { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', colSpan: 2 + (reportFiscalYearList.length * 6) }
            ])
          }
          pdfContent.push({
            table: {
              headerRows: 3,
              widths: '*',
              body: allRows
            }
          })
        } else {
          pdfContent.push({ text: vm.$t('globalTrans.noDataFound'), style: 'fertilizer', alignment: 'center' })
        }
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: {
          width: 1500,
          height: 1000
        },
        pageOrientation: 'Landcape',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          tdNone: {
            margin: [-10, -10, -10, -10],
            border: [false, false, false, false]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: (i18n.locale === 'bn') ? 18 : 16,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: (i18n.locale === 'bn') ? 20 : 18,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: (i18n.locale === 'bn') ? 13 : 12,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        },
        pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          if (currentNode.headlineLevel === 1) {
          }
          return currentNode.headlineLevel === 1
        },
        footer: function (currentPage, pageCount) {
          return [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' }]
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {
      }
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
