<template>
  <div>
    <b-overlay :show="loadSearch">
      <b-row>
        <b-col sm="3">
          <div class="card_item_wrapper inc_amount_wrapper">
            <div class="number_wrapper">
              <p class="number">
                <img src="../../../../../assets/images/ministry-dashboard/training.svg" class="item_icon">
              </p>
            </div>
            <div class="card_item">
              <p class="title">{{ $t('ministryDashboard.total_training') }}</p>
              <h2 class="count count_amount">{{$n(totalTraining)}}</h2>
            </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="card_item_wrapper inc_seed_wrapper">
            <div class="number_wrapper">
              <p class="number">
                <img src="../../../../../assets/images/ministry-dashboard/trainee.svg" class="item_icon">
              </p>
            </div>
            <div class="card_item">
              <p class="title">{{ $t('ministryDashboard.total_trainee') }}</p>
              <h2 class="count count_seed">{{$n(totalTrainee)}}</h2>
            </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="card_item_wrapper inc_fertilizer_wrapper">
            <div class="number_wrapper">
              <p class="number">
                  <img src="../../../../../assets/images/ministry-dashboard/official-trainee.svg" class="item_icon">
              </p>
            </div>
            <div class="card_item">
              <p class="title">{{ $t('ministryDashboard.official_trainee') }}</p>
              <h2 class="count count_fertilizer">{{$n(officialTrainee)}}</h2>
            </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="card_item_wrapper inc_farmer">
            <div class="number_wrapper">
              <p class="number">
                  <img src="../../../../../assets/images/ministry-dashboard/official-trainee.svg" class="item_icon">
              </p>
            </div>
            <div class="card_item">
              <p class="title">{{ $t('ministryDashboard.other_trainee') }}</p>
              <h2 class="count count_farmer">{{$n(otherTrainee)}}</h2>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col sm="7">
          <div class="grapChart_wrapper">
            <h5 class="card_title">{{ $t('ministryDashboard.year_wise_trainee_info') }}</h5>
            <apexchart width="500" type="bar" v-if="searchThis" :options="chartOptions" :series="seriesDatas"></apexchart>
          </div>
        </b-col>
        <b-col sm="5">
          <div class="grapChart_wrapper">
            <h5 class="card_title">{{ $t('ministryDashboard.male_female_trainee_status') }}</h5>
            <apexchart class="chart-1 text-center px-5" height="350" v-if="searchThis" type="pie" :options="spaceAvailableDashboardLabels" :series="series()"></apexchart>
          </div>
        </b-col>
      </b-row>
      <div class="text-right">
        <router-link :to="{ name: 'authSignUp.dashboard', params: {dashboardUrl: '/training-e-learning-service/report/training-summary-report' }}" class="details_btn inc_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import moment from 'moment'

export default {
  name: 'UiDataTable',
  data () {
    return {
      loadSearch: true,
      searchThis: false,
      totalTraining: 0,
      totalTrainee: 0,
      officialTrainee: 0,
      otherTrainee: 0,
      seriesData: [],
      search: {
        fiscal_year_id: 0
      },
      datas: [],
      firstLabel: ['Male', 'Female'],
      firstLabelBn: ['পুরুষ', 'মহিলা'],
      chartLabel: [],
      chartLabelBn: [],
      seriesDatas: [{
        name: 'series-1',
        data: []
      }],
      fiscalYearList: []
    }
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          height: 320,
          id: 'basic-bar'
        },
        xaxis: {
          // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
          categories: this.$i18n.locale === 'bn' ? this.chartLabelBn : this.chartLabel
        }
      }
    },
    spaceAvailableDashboardLabels () {
      return {
        chart: {
          height: 320,
          type: 'pie'
        },
        labels: this.$i18n.locale === 'bn' ? this.firstLabelBn : this.firstLabel,
        colors: ['#02ADEE', '#ED008C'],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },
    yearList: function () {
      const toBn = n => n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      var max = new Date().getFullYear()
      var min = max - 10
      var years = []
      for (var i = max; i >= min; i--) {
        const yearData = {}
        yearData.value = i
        yearData.text = this.$i18n.locale === 'bn' ? this.$n(i, { useGrouping: false }) : i
        yearData.text_en = i
        yearData.text_bn = toBn(String(i))
        years.push(yearData)
      }
      return years
    }
  },
  async created () {
    this.fiscalYear()
  },
  methods: {
    series () {
      return this.seriesData
    },
    yearCheck (value) {
      if (this.$i18n.locale === 'bn') {
         return moment(value).format('Y')
      } else {
        return moment(value).format('Y')
      }
    },
    async fiscalYear () {
      await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', {}).then(response => {
        if (response.success) {
          this.fiscalYearList = response.data.fiscalYearList
          this.setCurrentFiscalYear(this.fiscalYearList)
        }
      })
    },
    setCurrentFiscalYear (fiscalYearList) {
      if (fiscalYearList.length === 0) {
        return
      }
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth(), currentDate.getFullYear()]
      /** Define the year position  */
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      let currentFiscalYearId = 0
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          currentFiscalYearId = element.value
        }
      })
      this.search.fiscal_year_id = currentFiscalYearId
      this.loadData()
    },
    async loadData () {
      await RestApi.getData(trainingElearningServiceBaseUrl, 'ministry-dashboard', this.search).then(response => {
        if (response.success) {
          if (response.total_training) {
            this.totalTraining = response.total_training.total
          }
          if (response.total_trainee) {
            this.totalTrainee = response.total_trainee.total
          }
          if (response.official) {
            this.officialTrainee = response.official.total
          }
          if (response.other) {
            this.otherTrainee = response.other.total
          }
          if (response.male) {
            this.seriesData.push(response.male.total)
          }
          if (response.female) {
            this.seriesData.push(response.female.total)
          }
          const prevData = response.yearWiseTraining.map(item => {
            const yearData = this.yearCheck(item.created_at)
            const yearAssignData = {
              year_id: parseInt(yearData)
            }
            return Object.assign({}, item, yearAssignData)
          })
          const yearVal = []
          this.yearList.map(item => {
            const ministryDataMonthList = prevData.find(yearWiseTraining => parseInt(yearWiseTraining.year_id) === parseInt(item.value))
            if (typeof ministryDataMonthList !== 'undefined') {
              yearVal.push(ministryDataMonthList.total.toFixed(2))
            } else {
              yearVal.push(0)
            }
            this.chartLabelBn.push(item.text_bn)
            this.chartLabel.push(item.text_en)
          })
          this.seriesDatas = [{
            name: 'Year wise trainee info',
            data: yearVal
          }]
          this.searchThis = true
        }
        this.loadSearch = false
      })
    }
  }
}
</script>
