<template>
  <b-container fluid>
    <iq-card>
      <!-- <template v-slot:title>
        <span>{{ 'dsdfdsf' }}</span>
      </template> -->
      <template v-slot:body>
        <b-overlay>
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset"  enctype="multipart/form-data">
              <b-row>
                <b-col xs="12" sm="12" md="4" lg="4">
                  <ValidationProvider name="Fiscal Year" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="5" lg="5">
                  <ValidationProvider name="Production Season" rules="">
                    <b-form-group
                      class="row"
                      label-cols-sm="5"
                      label-for="production_season_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('seedsConfig.productionSeason')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.production_season_id"
                      :options="productionSeasonData"
                      id="production_season_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3">
                  <ValidationProvider name="Crop Type">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_type_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('ministryDashboard.cropType')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.crop_type_id"
                      :options="cropTypeData"
                      id="crop_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="crop_sub_type_id"
                    >
                    <template v-slot:label>
                    {{$t('ministryDashboard.crop_sub_type')}}
                    </template>
                    <b-form-select
                    plain
                    v-model="search.crop_sub_type_id"
                    :options="cropSubTypeData"
                    id="crop_sub_type_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="5" lg="5">
                  <ValidationProvider name="Crop Name">
                    <b-form-group
                      class="row"
                      label-cols-sm="5"
                      label-for="crop_name_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('ministryDashboard.cropName')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.crop_name_id"
                      :options="cropNameData"
                      id="crop_name_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3">
                  <b-button class="float-left" type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row v-show="showData">
                  <b-col md="12">
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('ministryDashboard.land_production_achievement_list') }}</h4>
                      </template>
                      <template v-slot:headerAction>
                        <b-button variant="primary" @click="pdfExport" class="mr-2">
                          {{  $t('globalTrans.export_pdf') }}
                        </b-button>
                      </template>
                      <template v-slot:body>
                        <b-overlay>
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="seeds/config/report-heading/detail" :org-id="2" :orgList="orgList">
                                    {{ $t('ministryDashboard.land_production_achievement_list')}}
                                  </list-report-head>
                                </b-col>
                            </b-row>
                              <b-row mt-5>
                                <!-- <div class="table-wrapper" v-if="reportFiscalYearList.length"> -->
                                <b-table-simple sticky-header bordered responsive thead-tr-class="bg-primary" v-if="reportFiscalYearList.length">
                                  <b-thead class="bg-primary">
                                  <b-tr>
                                    <b-th class="text-center" rowspan="3"> {{ $t('globalTrans.sl_no') }} </b-th>
                                    <b-th class="text-center" rowspan="3"> {{ $t('germConfig.cropName') }}</b-th>
                                    <template v-for="(item, index) in reportFiscalYearList">
                                    <b-th class="text-center" colspan="3" :key="'tb-heading-12-'+index"> {{ $t('ministryDashboard.pro') }}  {{ currentLocale === 'en' ? item.text_en : item.text_bn }}</b-th>
                                    <b-th class="text-center" colspan="3" :key="'tb-heading-11-'+index"> {{ $t('ministryDashboard.achievement') }} {{ currentLocale === 'en' ? item.text_en : item.text_bn }}</b-th>
                                    </template>
                                  </b-tr>
                                  <b-tr>
                                    <template v-for="(item, index) in reportFiscalYearList">
                                    <b-th class="text-center" :key="'tb-heading-21-'+index"> {{ $t('ministryDashboard.abadi_jomi')}}</b-th>
                                    <b-th class="text-center" :key="'tb-heading-22-'+index"> {{ $t('ministryDashboard.productions')}}</b-th>
                                    <b-th class="text-center" :key="'tb-heading-23-'+index"> {{ $t('ministryDashboard.yield')}}</b-th>
                                      <b-th class="text-center" :key="'tb-heading-24-'+index"> {{ $t('ministryDashboard.abadi_jomi')}}</b-th>
                                      <b-th class="text-center" :key="'tb-heading-25-'+index"> {{ $t('ministryDashboard.productions')}}</b-th>
                                      <b-th class="text-center" :key="'tb-heading-26-'+index"> {{ $t('ministryDashboard.yield')}}</b-th>
                                    </template>
                                  </b-tr>
                                  <b-tr>
                                    <template v-for="(item, index) in reportFiscalYearList">
                                      <b-th class="text-center" :key="'tb-heading-31-'+index"> {{ $t('ministryDashboard.lakh_hector')}}</b-th>
                                      <b-th class="text-center" :key="'tb-heading-32-'+index"> {{ $t('ministryDashboard.lakh_mt')}}</b-th>
                                      <b-th class="text-center" :key="'tb-heading-33-'+index"> {{ $t('ministryDashboard.ton_hector')}}</b-th>
                                      <b-th class="text-center" :key="'tb-heading-34-'+index"> {{ $t('ministryDashboard.lakh_hector')}}</b-th>
                                      <b-th class="text-center" :key="'tb-heading-35-'+index"> {{ $t('ministryDashboard.lakh_mt')}}</b-th>
                                      <b-th class="text-center" :key="'tb-heading-36-'+index"> {{ $t('ministryDashboard.ton_hector')}}</b-th>
                                    </template>
                                  </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                  <template v-if="reportCropInfoList.length">
                                    <template v-for="(cropType, cropTypeIndex) in reportCropInfoList">
                                      <b-tr :key="'ct-' + cropTypeIndex">
                                        <b-td class="text-center" :colspan="2 + (reportFiscalYearList.length * 6)">{{ getCropTypeName(cropType.crop_type_id) }}</b-td>
                                      </b-tr>
                                      <template v-for="(subType, subTypeIndex) in cropType.sub_type_list">
                                        <template v-for="(cropName, cropNameIndex) in subType.crop_name_list">
                                          <!-- If category list exits -->
                                          <template v-if="cropName.category_list.length > 0">
                                            <!-- If category list loop start -->
                                            <template v-for="(category, categoryIndex) in cropName.category_list">
                                              <template v-if="cropNameIndex === 0">
                                                <template v-if="categoryIndex === 0">
                                                  <b-tr :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex">
                                                    <b-td :rowspan="cropName.category_list.length ? cropName.category_list.length + 1 : null">{{ $n(subTypeIndex + 1) }}{{ '.' + $n(cropNameIndex + 1) }}</b-td>
                                                    <b-td sticky-column>{{ getCategoryName(category.category_id) }}</b-td>
                                                    <template v-for="(item, index) in reportFiscalYearList">
                                                      <template v-if="subTypeIndex === 0">
                                                        <template v-if="(!isFiscalYearExists(item.value)) || (!isCropTypeExists(item.value, cropType.crop_type_id))">
                                                          <b-td class="text-center align-middle" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex + 'fy-31-'+index+'-dnf'" :rowspan="cropType.total_row" colspan="6">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                        </template>
                                                        <template v-else>
                                                           <template v-if="!isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">
                                                               <b-td class="text-center align-middle" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex + '-fy-32-'+index+'-dnf'" :rowspan="subType.total_row" colspan="6">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                           </template>
                                                           <template v-else>
                                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-31-'+index">
                                                              {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target') }}
                                                            </b-td>
                                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-32-'+index">
                                                              {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target') }}
                                                            </b-td>
                                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-33-'+index">
                                                              {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output') }}
                                                            </b-td>
                                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-34-'+index">
                                                              {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement') }}
                                                            </b-td>
                                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-35-'+index">
                                                              {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement') }}
                                                            </b-td>
                                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-36-'+index">
                                                              {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output') }}
                                                            </b-td>
                                                          </template>
                                                        </template>
                                                      </template>
                                                      <!-- subTypeIndex is greater than 0 -->
                                                      <template v-else>
                                                        <template v-if="isFiscalYearExists(item.value)">
                                                          <template v-if="!isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">
                                                            <b-td class="text-center align-middle" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex + '-fy-32-'+index+'-dnf'" :rowspan="subType.total_row" colspan="6">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                          </template>
                                                          <template v-else>
<!--                                                            <template v-if="isCategoryExists(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id)">-->
                                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-31-'+index">
                                                                {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target') }}
                                                              </b-td>
                                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-32-'+index">
                                                                {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target') }}
                                                              </b-td>
                                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-33-'+index">
                                                                {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output') }}
                                                              </b-td>
                                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-34-'+index">
                                                                {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement') }}
                                                              </b-td>
                                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-35-'+index">
                                                                {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement') }}
                                                              </b-td>
                                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-36-'+index">
                                                                {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output') }}
                                                              </b-td>
<!--                                                            </template>-->
                                                          </template>
                                                        </template>
                                                      </template>
                                                    </template>
                                                  </b-tr>
                                                </template>
                                                <!-- If categoryIndex grater than 0 -->
                                                <template v-else>
                                                  <b-tr :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex">
                                                    <b-td sticky-column>{{ getCategoryName(category.category_id) }}</b-td>
                                                    <template v-for="(item, index) in reportFiscalYearList">
                                                      <template v-if="isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-31-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-32-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-33-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-34-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-35-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-36-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output') }}
                                                        </b-td>
                                                      </template>
                                                    </template>
                                                  </b-tr>
                                                </template>
                                              </template>
                                              <!-- If cropNameIndex greater than 0 start -->
                                              <template v-else>
                                                <!-- If categoryIndex equal to 0 start -->
                                                <template v-if="categoryIndex === 0">
                                                  <b-tr :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex">
                                                    <b-td :rowspan="cropName.category_list.length ? cropName.category_list.length + 1 : null">{{ $n(subTypeIndex + 1) }}{{ '.' + $n(cropNameIndex + 1) }}</b-td>
                                                    <b-td sticky-column>{{ getCategoryName(category.category_id) }}</b-td>
                                                    <template v-for="(item, index) in reportFiscalYearList">
                                                       <!-- If fiscal year exists condition start -->
                                                      <template v-if="isFiscalYearExists(item.value)">
                                                        <template v-if="!isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">
                                                          <b-td class="text-center align-middle" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex + '-fy-32-'+index+'-dnf'" :rowspan="subType.total_row" colspan="6">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                        </template>
                                                        <template v-else>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-31-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-32-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-33-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-34-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-35-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-36-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output') }}
                                                          </b-td>
                                                        </template>
                                                      </template>
                                                    </template>
                                                  </b-tr>
                                                </template>
                                                <!-- If categoryIndex greater than 0 start -->
                                                <template v-else>
                                                  <b-tr :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex">
                                                    <b-td sticky-column>{{ getCategoryName(category.category_id) }}</b-td>
                                                    <template v-for="(item, index) in reportFiscalYearList">
                                                      <template v-if="isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-31-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_target') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-32-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_target') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-33-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'target_output') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-34-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_land_achievement') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-35-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'total_production_achievement') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-ct-'+categoryIndex +'fy-36-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, category.category_id, 'achievement_output') }}
                                                        </b-td>
                                                      </template>
                                                    </template>
                                                  </b-tr>
                                                </template>
                                              </template>
                                            </template>
                                            <!-- If category list loop start -->
                                            <b-tr :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-tot'">
                                              <b-td sticky-column>{{ $t('globalTrans.total') + '  ' + getSpecificCropName(cropName.crop_name_id) + ' :' }}</b-td>
                                              <template v-for="(item, index) in reportFiscalYearList">
                                                <template v-if="isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">
                                                  <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-c1-total'+index">
                                                    {{ isCropNameExists(item.value,
                                                    cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && getCategoryTotal(item.value,
                                                    cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                                                    'total_land_target', false) > 0 ? getCategoryTotal(item.value,
                                                    cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id,
                                                    'total_land_target') : '' }}
                                                  </b-td>
                                                  <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-c2-total'+index">
                                                    {{ isCropNameExists(item.value,
                                                    cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'total_production_target', false) > 0 ? getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'total_production_target') : '' }}
                                                  </b-td>
                                                  <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-c3-total'+index">
                                                    {{ isCropNameExists(item.value,
                                                    cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'target_output', false) > 0 ? getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'target_output') : '' }}
                                                  </b-td>
                                                  <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-c4-total'+index">
                                                    {{ isCropNameExists(item.value,
                                                    cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'total_land_achievement', false) > 0 ? getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'total_land_achievement') : '' }}
                                                  </b-td>
                                                  <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-c5-total'+index">
                                                    {{ isCropNameExists(item.value,
                                                    cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'total_production_achievement', false) > 0 ? getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'total_production_achievement') : '' }}
                                                  </b-td>
                                                  <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-c6-total'+index">
                                                    {{ isCropNameExists(item.value,
                                                    cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id) && getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'achievement_output', false) > 0 ? getCategoryTotal(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, 'achievement_output') : '' }}
                                                  </b-td>
                                                </template>
                                              </template>
                                            </b-tr>
                                          </template>

                                          <!-- If category list not found -->
                                          <template v-else>
                                            <b-tr :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'">
                                              <b-td>{{ $n(subTypeIndex + 1) }}{{ '.' + $n(cropNameIndex + 1) }}</b-td>
                                              <b-td sticky-column>{{ getSpecificCropName(cropName.crop_name_id) }}</b-td>
                                              <template v-for="(item, index) in reportFiscalYearList">
                                                <template v-if="subTypeIndex === 0">
                                                  <template v-if="cropNameIndex === 0">
                                                    <template v-if="(!isFiscalYearExists(item.value)) || (!isCropTypeExists(item.value, cropType.crop_type_id))">
                                                      <b-td class="text-center align-middle" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-31-'+index+'-dnf'" :rowspan="cropType.total_row" colspan="6">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                    </template>
                                                    <template v-else>                                                                                                                            <template v-if="!isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">                                         <b-td class="text-center align-middle" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat-'+'fy-32-'+index+'-dnf'" :rowspan="subType.total_row" colspan="6">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                                                                                                                                                                  </template>
                                                        <template v-else>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-31-'+index">
                                                           {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_target') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-32-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_target') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-33-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'target_output') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-34-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_achievement') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-35-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_achievement') }}
                                                          </b-td>
                                                          <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-36-'+index">
                                                            {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'achievement_output') }}
                                                          </b-td>                                                                                                           </template>
                                                        <!-- v-else end -->
                                                    </template>
                                                    <!-- v-else end -->
                                                  </template>
                                                  <!-- cropNameIndex === 0 if condition end -->
                                                  <!-- cropNameIndex === 0 else start -->
                                                  <template v-else>
                                                    <template v-if="isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">
                                                      <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-31-'+index">
                                                        {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_target') }}
                                                      </b-td>
                                                      <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-32-'+index">
                                                        {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_target') }}
                                                      </b-td>
                                                      <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-33-'+index">
                                                        {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'target_output') }}
                                                      </b-td>
                                                      <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-34-'+index">
                                                        {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_achievement') }}
                                                      </b-td>
                                                      <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-35-'+index">
                                                        {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_achievement') }}
                                                      </b-td>
                                                      <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-36-'+index">
                                                        {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'achievement_output') }}
                                                      </b-td>
                                                    </template>
                                                  </template>
                                                </template>
                                                <!-- subTypeIndex === 0 if condition end -->
                                                <!-- subTypeIndex === 0 else condition start -->
                                                <template v-else>
                                                  <template v-if="cropNameIndex === 0">
                                                    <template v-if="isFiscalYearExists(item.value)">
                                                      <template v-if="!isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">
                                                        <b-td class="text-center align-middle" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat-'+'fy-32-'+index+'-dnf'" :rowspan="subType.total_row" colspan="6">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                      </template>
                                                      <template v-else>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-31-'+index">
                                                         {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_target') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-32-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_target') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-33-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'target_output') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-34-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_achievement') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-35-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_achievement') }}
                                                        </b-td>
                                                        <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-36-'+index">
                                                          {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'achievement_output') }}
                                                        </b-td>                                                                                                            </template>
                                                    </template>
                                                  </template>
                                                   <!-- cropNameIndex === 0 if condition end -->
                                                  <!-- cropNameIndex === 0 else condition start -->
                                                  <template v-else>
                                                    <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-31-'+index">
                                                     {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_target') }}
                                                    </b-td>
                                                    <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-32-'+index">
                                                      {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_target') }}
                                                    </b-td>
                                                    <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-33-'+index">
                                                      {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'target_output') }}
                                                    </b-td>
                                                    <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-34-'+index">
                                                      {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_land_achievement') }}
                                                    </b-td>
                                                    <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-35-'+index">
                                                      {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'total_production_achievement') }}
                                                    </b-td>
                                                    <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-'+cropNameIndex+'-no-cat'+'fy-36-'+index">
                                                      {{ getCropInfoAmount(item.value, cropType.crop_type_id, subType.sub_type_id, cropName.crop_name_id, '-1', 'achievement_output') }}
                                                    </b-td>                                                                                                                                 </template>
                                                </template>
                                                <!-- subTypeIndex === 0 else condition end -->
                                              </template>
                                            </b-tr>
                                          </template>
                                        </template>
                                        <b-tr :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-tot'" v-if="subType.sub_type_id > 0">
                                          <b-td colspan="2" sticky-column>{{ $t('globalTrans.total') + '  ' + getCropSubTypeName(subType.sub_type_id) + ' :' }}</b-td>
                                          <template v-for="(item, index) in reportFiscalYearList">
                                            <template v-if="isFiscalYearExists(item.value) && isSubTypeExists(item.value, cropType.crop_type_id, subType.sub_type_id)">
                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-total-31-'+index">
                                                {{ getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_land_target', false) > 0 ? getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_land_target') : '' }}
                                              </b-td>
                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-total-32-'+index">
                                                {{ getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_production_target', false) > 0 ? getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_production_target') : '' }}
                                              </b-td>
                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-total-33-'+index">
                                                {{ getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'target_output', false) > 0 ? getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'target_output') : '' }}
                                              </b-td>
                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-total-34-'+index">
                                                {{ getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_land_achievement', false) > 0 ? getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_land_achievement') : '' }}
                                              </b-td>
                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-total-35-'+index">
                                                {{ getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_production_achievement', false) > 0 ? getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'total_production_achievement') : '' }}
                                              </b-td>
                                              <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-st-'+subTypeIndex+'-cn-total-36-'+index">
                                                {{ getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'achievement_output', false) > 0 ? getCropNameTotal(item.value, cropType.crop_type_id, subType.sub_type_id, 'achievement_output') : '' }}
                                              </b-td>
                                            </template>
                                          </template>
                                        </b-tr>
                                      </template>
                                      <b-tr :key="'ct-'+cropTypeIndex+'-tot'">
                                        <b-td colspan="2" sticky-column>{{ $t('globalTrans.total') + '  ' + getCropTypeName(cropType.crop_type_id) + ' :' }}</b-td>
                                        <template v-for="(item, index) in reportFiscalYearList">
                                          <template v-if="isFiscalYearExists(item.value) && isCropTypeExists(item.value, cropType.crop_type_id)">
                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-cn-total-31-'+index">
                                              {{ getSubTypeTotal(item.value, cropType.crop_type_id, 'total_land_target', false) > 0 ? getSubTypeTotal(item.value, cropType.crop_type_id, 'total_land_target') : '' }}
                                            </b-td>
                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-cn-total-32-'+index">
                                              {{ getSubTypeTotal(item.value, cropType.crop_type_id, 'total_production_target', false) > 0 ? getSubTypeTotal(item.value, cropType.crop_type_id, 'total_production_target') : '' }}
                                            </b-td>
                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-cn-total-33-'+index">
                                              {{ getSubTypeTotal(item.value, cropType.crop_type_id, 'target_output', false) > 0 ? getSubTypeTotal(item.value, cropType.crop_type_id, 'target_output') : '' }}
                                            </b-td>
                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-cn-total-34-'+index">
                                              {{ getSubTypeTotal(item.value, cropType.crop_type_id, 'total_land_achievement', false) > 0 ? getSubTypeTotal(item.value, cropType.crop_type_id, 'total_land_achievement') : '' }}
                                            </b-td>
                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-cn-total-35-'+index">
                                              {{ getSubTypeTotal(item.value, cropType.crop_type_id, 'total_production_achievement', false) > 0 ? getSubTypeTotal(item.value, cropType.crop_type_id, 'total_production_achievement') : '' }}
                                            </b-td>
                                            <b-td class="text-center" :key="'ct-'+cropTypeIndex+'-cn-total-36-'+index">
                                              {{ getSubTypeTotal(item.value, cropType.crop_type_id, 'achievement_output', false) > 0 ? getSubTypeTotal(item.value, cropType.crop_type_id, 'achievement_output') : '' }}
                                            </b-td>
                                          </template>
                                        </template>
                                      </b-tr>
                                    </template>
                                </template>
                                <template v-else>
                                  <b-tr>
                                    <b-td :colspan="2 + (reportFiscalYearList.length * 6)" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                  </b-tr>
                                </template>
                                </b-tbody>
                                </b-table-simple>
                                <!-- </div> -->
                                <div v-else class="text-center">
                                  <h2>{{ $t('globalTrans.noDataFound') }}</h2>
                                </div>
                              </b-row>
                        </b-overlay>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { landProductionAchievementReport } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadMinister.vue'
import Pdf from './LandProductionAchievementReportPdf'

export default {
  props: ['fiscalYearList', 'currentFiscalYearId'],
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      deliveryScheduleList: [],
      search: {
        fiscal_year_id: 0,
        fiscal_year_id_arr: [],
        production_season_id: 0,
        crop_type_id: 0,
        crop_sub_type_id: 0,
        crop_name_id: 0
      },
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      CropTypeList: [],
      cropSubTypeList: [],
      cropNameList: [],
      productionSeasonList: [],
      dataLoading: false,
      reportData: [],
      grantTotalObj: {
        land_target: 0,
        total_land_achievement: 0,
        production_target: 0,
        total_crop_harvesting: 0,
        total_production_achievement: 0
      },
      loadingData: false,
      currFiscalYearId: 0,
      prevFiscalYearId: 0,
      nextFiscalYearId: 0,
      reportFiscalYearList: [],
      reportCropInfoList: [],
      reportFiscalYearInfoList: [],
      orgList: []
    }
  },
  created () {
    this.getCommonDropdownData()
    // this.getFiscalYear()
    this.getOrgList()
    this.search.fiscal_year_id = this.currentFiscalYearId
  },
  mounted () {
    core.index()
  },
  computed: {
    cropTypeData: function () {
      const cropData = this.CropTypeList
      const tempData = cropData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    productionSeasonData: function () {
      const seasons = this.productionSeasonList.filter(item => item.status === 1)
      const tmpData = seasons.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tmpData
    },
    cropSubTypeData: function () {
      const subTypes = this.cropSubTypeList
      const tmpData = subTypes.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tmpData
    },
    cropNameData: function () {
      const cropNames = this.cropNameList
      const tmpData = cropNames.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tmpData
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    currentFiscalYearId: function (newVal, oldVal) {
      this.search.fiscal_year_id = newVal
    },
    'search.crop_type_id': function (newVal, oldVal) {
      if (newVal) {
        this.cropNameList = this.getCropName(newVal)
        this.cropSubTypeList = this.getCropSubType(newVal)
      }
    },
    'search.fiscal_year_id': function (newVal, oldVal) {
      if (newVal) {
        this.search.fiscal_year_id_arr = []
        this.reportFiscalYearList = []
        const currentFiscalYear = this.fiscalYearList.find(item => item.value === parseInt(newVal))
        if (currentFiscalYear !== undefined) {
          this.currFiscalYearId = currentFiscalYear.value
          const previousFiscalYearList = this.fiscalYearList.filter(item => item.sorting_order < currentFiscalYear.sorting_order).sort((prev, curr) => { return curr.sorting_order - prev.sorting_order })
          if (previousFiscalYearList.length) {
            this.prevFiscalYearId = previousFiscalYearList[0].value
            this.search.fiscal_year_id_arr.push(parseInt(this.prevFiscalYearId))
            this.reportFiscalYearList.push(previousFiscalYearList[0])
          }
          this.search.fiscal_year_id_arr.push(parseInt(this.currFiscalYearId))
          this.reportFiscalYearList.push(currentFiscalYear)
          const nextFiscalYearList = this.fiscalYearList.filter(item => item.sorting_order > currentFiscalYear.sorting_order).sort((prev, curr) => { return prev.sorting_order - curr.sorting_order })
          if (nextFiscalYearList.length) {
            this.nextFiscalYearId = nextFiscalYearList[0].value
            this.search.fiscal_year_id_arr.push(parseInt(this.nextFiscalYearId))
            this.reportFiscalYearList.push(nextFiscalYearList[0])
          }
        }
      } else {
        this.search.fiscal_year_id_arr = []
      }
    }
  },
  methods: {
  getTotalLand (datas) {
    let totalLandAmount = 0
    let totCropHarvesting = 0
    let productionTarget = 0
    let totalProductionAchievement = 0
    let totalLandAchievement = 0

    datas.forEach(item => {
      totalLandAmount += item.land_target
      totCropHarvesting += item.total_crop_harvesting
      productionTarget += item.production_target
      totalProductionAchievement += item.total_production_achievement
      totalLandAchievement += item.total_land_achievement
    })

    this.grantTotalObj.land_target = totalLandAmount
    this.grantTotalObj.total_crop_harvesting = totCropHarvesting
    this.grantTotalObj.production_target = productionTarget
    this.grantTotalObj.total_production_achievement = totalProductionAchievement
    this.grantTotalObj.total_land_achievement = totalLandAchievement
    },
    searchData () {
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    async loadData () {
      this.reportCropInfoList = []
      this.reportFiscalYearInfoList = []
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, landProductionAchievementReport, params).then(response => {
        if (response.success) {
          if (response.data) {
            this.reportCropInfoList = response.data
            this.reportFiscalYearInfoList = response.fiscalYearData
            // this.getTotalLand(response.data)
          }
        } else {
          this.deliveryScheduleList = []
        }
      })
      this.loadingData = false
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async getOrgList () {
      await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', {}).then(response => {
        if (response.success) {
          this.orgList = response.data.orgList
        }
      })
    },
    async getCommonDropdownData () {
      this.dataLoading = true
      await RestApi.getData(seedFertilizerServiceBaseUrl, 'ministry-crop-dropdown', {}).then(response => {
        if (response.success) {
          this.CropTypeList = this.getCommonFormat(response.data.CropTypeList)
          this.cropNameList = this.getCommonFormat(response.data.CropNameList)
          this.seedSubTypeList = this.getCommonFormat(response.data.seedSubTypeList)
          this.productionSeasonList = this.getCommonFormat(response.data.productionSeasonList)
          this.seedCategoryList = this.getCommonFormat(response.data.seedCategoryList)
        }
      })
      this.dataLoading = false
    },
    getCommonFormat (data) {
      return data.map(item => {
        const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    translateDropdown () {
      this.fiscalYearList.map(item => {
        const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    getCropTypeName (id) {
      const cropType = this.CropTypeList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'bn' ? cropType?.text_bn : cropType?.text_en
    },
    getCropSubType (id) {
        return this.seedSubTypeList.filter(item => item.status === 1 && item.crop_type_id === id)
    },
    getCropSubTypeName (id) {
      const obj = this.seedSubTypeList.find(item => item.value === id)
      return this.currentLocale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getCropName (id) {
      return this.CropNameList.filter(item => item.status === 1 && item.crop_type_id === id)
    },
    getSpecificCropName (ID) {
      const cropNameObj = this.cropNameList.find(item => item.value === parseInt(ID))
      return cropNameObj !== undefined ? (this.currentLocale === 'en' ? cropNameObj.text_en : cropNameObj.text_bn) : ''
    },
    getCategoryName (id) {
      const obj = this.seedCategoryList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    fiscalYearBnAdd (data) {
      const bnList = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
      const bnAdded = data.map(item => {
        const fiscalYearData = {}
        const result = item.text_bn.split('').map(item => item === '-' ? '-' : bnList[item])
        fiscalYearData.text_bn = result.join('')
        return Object.assign({}, item, fiscalYearData)
      })
      return bnAdded
    },
    isFiscalYearExists (fiscalYearId) {
      return this.reportFiscalYearInfoList[fiscalYearId.toString()] !== undefined
    },
    isCropTypeExists (fiscalYearId, cropTypeId) {
      return this.isFiscalYearExists(fiscalYearId) && this.reportFiscalYearInfoList[fiscalYearId.toString()][cropTypeId.toString()] !== undefined
    },
    isSubTypeExists (fiscalYearId, cropTypeId, subTypeId) {
      return this.isCropTypeExists(fiscalYearId, cropTypeId) && this.reportFiscalYearInfoList[fiscalYearId.toString()][cropTypeId.toString()][subTypeId.toString()] !== undefined
    },
    isCropNameExists (fiscalYearId, cropTypeId, subTypeId, cropNameId) {
      return this.isSubTypeExists(fiscalYearId, cropTypeId, subTypeId) && this.reportFiscalYearInfoList[fiscalYearId.toString()][cropTypeId.toString()][subTypeId.toString()][cropNameId.toString()] !== undefined
    },
    isCategoryExists (fiscalYearId, cropTypeId, subTypeId, cropNameId, categoryId) {
      return this.isCropNameExists(fiscalYearId, cropTypeId, subTypeId, cropNameId) && this.reportFiscalYearInfoList[fiscalYearId.toString()][cropTypeId.toString()][subTypeId.toString()][cropNameId.toString()][categoryId.toString()] !== undefined
    },
    getCropInfoAmount (fiscalYearId, cropTypeId, subTypeId, cropNameId, categoryId, propertyName) {
      let amount = ''
      // const obj = this.reportFiscalYearInfoList[fiscalYearId][cropTypeId.toString()][subTypeId.toString()][cropNameId.toString()]
      // return (obj !== undefined && obj[categoryId] !== undefined && obj[categoryId][propertyName]) > 0 ? this.$n(obj[categoryId][propertyName]) : ''
      if (this.isCategoryExists(fiscalYearId, cropTypeId, subTypeId, cropNameId, categoryId)) {
        const obj = this.reportFiscalYearInfoList[fiscalYearId][cropTypeId.toString()][subTypeId.toString()][cropNameId.toString()][categoryId.toString()]
        amount = obj[propertyName]
      }
      // return !(amount === undefined && isNaN(amount)) ? this.$n(parseInt(amount)) : ''
      return amount ? this.$n(parseFloat(amount)) : ''
    },
    getCategoryTotal (fiscalYearId, cropTypeId, subTypeId, cropNameId, propertyName, withFormat = true) {
      let total = 0
      if (this.isCropNameExists(fiscalYearId, cropTypeId, subTypeId, cropNameId)) {
        if (propertyName === 'target_output') {
          const totalProductionTarget = this.getCategoryTotal(fiscalYearId, cropTypeId, subTypeId, cropNameId, 'total_production_target', false)
          const totalLandTarget = this.getCategoryTotal(fiscalYearId, cropTypeId, subTypeId, cropNameId, 'total_land_target', false)
          if (parseFloat(totalLandTarget) > 0) {
            total = parseFloat(totalProductionTarget) / parseFloat(totalLandTarget)
          }
        } else if (propertyName === 'achievement_output') {
          const totalProductionAchievement = this.getCategoryTotal(fiscalYearId, cropTypeId, subTypeId, cropNameId, 'total_production_achievement', false)
          const totalLandAchievement = this.getCategoryTotal(fiscalYearId, cropTypeId, subTypeId, cropNameId, 'total_land_achievement', false)
          if (parseFloat(totalLandAchievement) > 0) {
            total = parseFloat(totalProductionAchievement) / parseFloat(totalLandAchievement)
          }
        } else {
          const obj = this.reportFiscalYearInfoList[fiscalYearId][cropTypeId.toString()][subTypeId.toString()][cropNameId.toString()]
          if (obj !== undefined) {
            for (const categoryId in obj) {
              total += parseFloat(obj[categoryId][propertyName])
            }
          }
        }
      }

      return withFormat ? this.$n(total) : total
    },
    getCropNameTotal (fiscalYearId, cropTypeId, subTypeId, propertyName, withFormat = true) {
      let total = 0
      if (this.isSubTypeExists(fiscalYearId, cropTypeId, subTypeId)) {
        if (propertyName === 'target_output') {
          const totalProductionTarget = this.getCropNameTotal(fiscalYearId, cropTypeId, subTypeId, 'total_production_target', false)
          const totalLandTarget = this.getCropNameTotal(fiscalYearId, cropTypeId, subTypeId, 'total_land_target', false)
          if (parseFloat(totalLandTarget) > 0) {
            total = parseFloat(totalProductionTarget) / parseFloat(totalLandTarget)
          }
        } else if (propertyName === 'achievement_output') {
          const totalProductionAchievement = this.getCropNameTotal(fiscalYearId, cropTypeId, subTypeId, 'total_production_achievement', false)
          const totalLandAchievement = this.getCropNameTotal(fiscalYearId, cropTypeId, subTypeId, 'total_land_achievement', false)
          if (parseFloat(totalLandAchievement) > 0) {
            total = parseFloat(totalProductionAchievement) / parseFloat(totalLandAchievement)
          }
        } else {
          const subTypeObj = this.reportFiscalYearInfoList[fiscalYearId][cropTypeId.toString()][subTypeId.toString()]
          if (subTypeObj !== undefined) {
            for (const cropNameId in subTypeObj) {
              const cropNameObj = subTypeObj[cropNameId]
              if (cropNameObj !== undefined) {
                for (const categoryId in cropNameObj) {
                  total += parseFloat(cropNameObj[categoryId][propertyName])
                }
              }
            }
          }
        }
      }

      return withFormat ? this.$n(total) : total
    },
    getSubTypeTotal (fiscalYearId, cropTypeId, propertyName, withFormat = true) {
      let total = 0
      if (this.isCropTypeExists(fiscalYearId, cropTypeId)) {
        if (propertyName === 'target_output') {
          const totalProductionTarget = this.getSubTypeTotal(fiscalYearId, cropTypeId, 'total_production_target', false)
          const totalLandTarget = this.getSubTypeTotal(fiscalYearId, cropTypeId, 'total_land_target', false)
          if (parseFloat(totalLandTarget) > 0) {
            total = parseFloat(totalProductionTarget) / parseFloat(totalLandTarget)
          }
        } else if (propertyName === 'achievement_output') {
          const totalProductionAchievement = this.getSubTypeTotal(fiscalYearId, cropTypeId, 'total_production_achievement', false)
          const totalLandAchievement = this.getSubTypeTotal(fiscalYearId, cropTypeId, 'total_land_achievement', false)
          if (parseFloat(totalLandAchievement) > 0) {
            total = parseFloat(totalProductionAchievement) / parseFloat(totalLandAchievement)
          }
        } else {
          const cropType = this.reportFiscalYearInfoList[fiscalYearId][cropTypeId.toString()]
          if (cropType !== undefined) {
            for (const subTypeId in cropType) {
              total += parseFloat(this.getCropNameTotal(fiscalYearId, cropTypeId, parseInt(subTypeId), propertyName, false))
            }
          }
        }
      }
      return withFormat ? this.$n(total) : total
    },
    pdfExport () {
      const reportTitle = this.$t('ministryDashboard.land_production_achievement_list')
      Pdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', 2, reportTitle, this)
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
 .table-wrapper {
   max-height: 300px;
   overflow-y: scroll;
 }
 .table.b-table>thead,
 .table.b-table>thead>tr>th {
   background: #609513;
   color: #fff;
   font-weight: bold;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 11;
 }
  /* .table.b-table>thead>tr>th:first-child {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
 } */
 /* table {
   table-layout: fixed;
 } */
 /* .table.b-table tbody tr td:first-child[colspan="#2"],
 .table.b-table tbody tr th:first-child[colspan="#2"],
 .table.b-table tbody tr td:nth-child(2),
 .table.b-table tbody tr th:nth-child(2)
  {
    background: white;
    position: sticky;
    left: 0;
    z-index: 9;
  } */
  .table.b-table,
 .table.b-table tbody td,
 .table.b-table tbody th {
   position: relative;
   z-index: 1;
  }
</style>
