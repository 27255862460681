<template>
    <div class="minister_wrapper">
        <b-overlay :show="loading">
            <div class="org_filter_wrapper">
                <b-row>
                    <b-col sm="3" class="mb-3">
                        <label for="fiscal_year_id" class="form-label mb-0">{{$t('dae_grant_allocation_distribution.fiscal_year')}}</label>
                        <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalYearList"
                            id="fiscal_year_id"
                            class="bg-white"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.all')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>
                    <b-col sm="3" class="mb-3">
                        <label for="service_type_id" class="form-label mb-0">{{ $t('ministryDashboard.service_type') }}</label>
                        <b-form-select
                            plain
                            v-model="search.service_type_id"
                            :options="serviceTypeList"
                            id="project_id"
                            class="bg-white"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.all')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-col>
                    <b-col>
                        <div class="d-flex align-items-center h-100">
                            <button type="submit" class="btn btn-primary mt-2" @click="searchData"><i class="fas fa-search"></i> {{ $t('globalTrans.search') }}</button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-row class="justify-content-center" v-if="data">
                <b-col sm="3"></b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper inc_amount_wrapper">
                        <div class="number_wrapper">
                        <p class="number">
                            <img src="../../../../../assets/images/ministry-dashboard/total-amount.svg" class="item_icon">
                        </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('globalTrans.total_amount') }} {{ $t('globalTrans.tk') }}</p>
                            <h2 class="count count_amount">
                                {{ EngBangNum(data.total_amount) }}
                                <sub>{{ $t('globalTrans.lakh') }}</sub>
                            </h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper inc_farmer">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../assets/images/ministry-dashboard/total-farmer.svg" class="item_icon">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('globalTrans.no_farmer') }}</p>
                            <h2 class="count count_farmer">{{ $n(data.total_farmer) }}</h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3"></b-col>
            </b-row>
            <b-row class="justify-content-center" v-if="data && incentiveRbShow">
                <b-col sm="3">
                    <div class="card_item_wrapper inc_seed_wrapper">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../assets/images/ministry-dashboard/total-seed.svg" class="item_icon">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('globalTrans.total_seed') + ' (' + $t('globalTrans.quantity') + ')' }}</p>
                            <h2 class="count count_seed">
                                {{ EngBangNum(data.total_seed_quantity) }}
                                <sub>{{ $t('globalTrans.m_ton') }}</sub>
                            </h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper inc_amount_wrapper">
                        <div class="number_wrapper">
                        <p class="number">
                            <img src="../../../../../assets/images/ministry-dashboard/total-amount.svg" class="item_icon">
                        </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('globalTrans.total_seed') + ' ' + $t('globalTrans.tk') }}</p>
                            <h2 class="count count_amount">
                                {{ EngBangNum(data.total_seed_amount) }}
                                <sub>{{ $t('globalTrans.lakh') }}</sub>
                            </h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper inc_fertilizer_wrapper">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../assets/images/ministry-dashboard/total-fertilizer.svg" class="item_icon">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('globalTrans.total_fertilizer') + ' (' + $t('globalTrans.quantity') + ')' }}</p>
                            <h2 class="count count_fertilizer">
                                {{ EngBangNum(data.total_fertilizer_quantity) }}
                                <sub>{{ $t('globalTrans.m_ton') }}</sub>
                            </h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper inc_amount_wrapper">
                        <div class="number_wrapper">
                        <p class="number">
                            <img src="../../../../../assets/images/ministry-dashboard/total-amount.svg" class="item_icon">
                        </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('globalTrans.total_fertilizer') + ' ' +  $t('globalTrans.tk') }}</p>
                            <h2 class="count count_amount">
                                {{ EngBangNum(data.total_fertilizer_amount) }}
                                <sub>{{ $t('globalTrans.lakh') }}</sub>
                            </h2>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col sm="8">
                    <div class="grapChart_wrapper" style="height: 400px">
                        <h5 class="card_title">{{ $t('ministryDashboard.fiscalYearWiseFarmer') }}</h5>
                        <apexchart class="chart-1 text-center px-5" id="vuechart-example" type="bar" height="300" :options="dataOption" :series="series"></apexchart>
                    </div>
                </b-col>
            </b-row>
            <div class="text-right">
                <router-link :to="{ name: 'authSignUp.dashboard', params: {dashboardUrl: '/incentive-grant-service/dae/subsidy/subsidy-distribution-report' }}" class="details_btn inc_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ministryDashboard } from '../../api/routes'

export default {
    name: 'IncentiveDaeDashboard',
    props: ['fiscalYearList', 'currentFiscalYearId'],
    data () {
      return {
        loading: false,
        incentiveRbShow: true,
        data: null,
        seriesData: [],
        labelList: [],
        // fiscalYearList: [],
        // currentFiscalYearId: '',
        search: {
            fiscal_year_id: 0,
            service_type_id: 0
        },
        serviceTypes: [
            {
                text_en: 'Subsidy',
                text_bn: 'ভর্তুকি',
                value: 1
            },
            {
                text_en: 'Incentive/Rehabilitation',
                text_bn: 'প্রণোদনা/পুনর্বাসন',
                value: 2
            },
            {
                text_en: 'Grant',
                text_bn: 'অনুদান',
                value: 3
            }
        ]
      }
    },
    computed: {
        currentLocale: function () {
            return this.$i18n.locale
        },
        serviceTypeList: function () {
            return this.serviceTypes.map(item => {
                const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
                return Object.assign({}, item, tmp)
            })
        },
        series: function () {
          return [{
            name: this.$t('sitePreference.total'),
            data: this.seriesData
          }]
        },
        dataOption () {
          return {
            chart: {
              id: 'vuechart-example',
              type: 'bar'
            },
            plotOptions: {
              bar: {
                barHeight: '30px',
                columnWidth: '65%',
                distributed: true,
                horizontal: false,
                dataLabels: {
                  position: 'bottom',
                  horizontalAlign: 'center'
                }
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val
              },
              offsetY: -1,
              style: {
                fontSize: '14px',
                colors: ['#fff']
              }
            },
            labels: this.labelList
          }
        }
    },
    created () {
        this.loadData()
        // this.getFiscalYear()
    },
    watch: {
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.translateFiscalYear()
            }
        }
    },
    mounted () {
      core.index()
    },
    methods: {
        searchData () {
            this.loadData()
        },
        // async getFiscalYear () {
        //     await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', {}).then(response => {
        //         if (response.success) {
        //             this.fiscalYearList = this.getCommonFormat(response.data.fiscalYearList)
        //             this.setCurrentFiscalYear(this.fiscalYearList)
        //         }
        //     })
        // },
        async loadData () {
            this.loading = true
            await RestApi.getData(incentiveGrantServiceBaseUrl, ministryDashboard, this.search)
            .then(response => {
                if (response.success) {
                    this.data = response.data
                    const serviceType = parseInt(response.data.service_type_id)
                    if (serviceType === 2 || serviceType === 0) {
                        this.incentiveRbShow = true
                    } else {
                        this.incentiveRbShow = false
                    }
                    this.getSeriesAndLabel(response.data.farmers)
                } else {
                    this.data = {}
                }
                this.loading = false
            })
        },
        // setCurrentFiscalYear (fiscalYearList) {
        //     if (fiscalYearList.length === 0) {
        //         return
        //     }
        //     const currentDate = new Date()
        //     const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
        //     /** Define the year position  */
        //     const yearPosition = month < 7 ? 5 : 0
        //     const yearStr = `${year}`
        //     fiscalYearList.forEach(element => {
        //         if (element.text_en.indexOf(yearStr) === yearPosition) {
        //             this.currentFiscalYearId = element.value
        //             this.currentFiscalSortingId = element.sorting_order
        //         }
        //     })
        //     this.search.fiscal_year_id = this.currentFiscalYearId
        //     this.loadData()
        // },
        getSeriesAndLabel (data) {
            const currentFiscalYear = this.fiscalYearList.find(el => el.value === this.currentFiscalYearId)
            const currentFiscalSortingId = currentFiscalYear.sorting_order
            const tmpFiscalYears = this.fiscalYearList.filter(el => {
                if (el.sorting_order <= currentFiscalSortingId) {
                    return el
                }
            })
            const finalData = tmpFiscalYears.map(item => {
                const currentData = data.find(tmp => tmp.fiscal_year_id === item.value)
                return Object.assign(item, { total_farmer: currentData !== undefined ? parseInt(currentData.total_farmer) : 0 })
            })
            const lastFiveRecords = finalData.reverse().slice(0, 5).reverse()
            this.seriesData = []
            this.labelList = []
            return lastFiveRecords.map(item => {
                this.seriesData.push(item.total_farmer)
                this.labelList.push(this.currentLocale === 'bn' ? item.text_bn : item.text_en)
            })
        },
        getCommonFormat (data) {
            return data.map(item => {
                const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
                return Object.assign({}, item, tmp)
            })
        },
        translateFiscalYear () {
            this.fiscalYearList.map(item => {
                const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
                return Object.assign({}, item, tmp)
            })
        },
        EngBangNum (n) {
            if (this.currentLocale === 'bn') {
                return n.toString().replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            } else {
                return n.toString().replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
            }
        }
    }
}
</script>

<style scoped>
    .card_item .count sub {
        font-size: 1rem;
        bottom: 0;
    }
</style>
