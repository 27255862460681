<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-overlay>
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset"  enctype="multipart/form-data">
              <b-row>
                <b-col xs="12" sm="12" md="4" lg="4">
                  <ValidationProvider name="Fiscal Year" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="5" lg="5">
                  <ValidationProvider name="Production Season" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="5"
                      label-for="production_season_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('seedsConfig.productionSeason')}}<span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      plain
                      v-model="search.production_season_id"
                      :options="productionSeasonData"
                      id="production_season_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3">
                  <ValidationProvider name="Crop Type">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_type_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('ministryDashboard.cropType')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.crop_type_id"
                      :options="cropTypeData"
                      id="crop_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="crop_sub_type_id"
                    >
                    <template v-slot:label>
                    {{$t('ministryDashboard.crop_sub_type')}}
                    </template>
                    <b-form-select
                    plain
                    v-model="search.crop_sub_type_id"
                    :options="cropSubTypeData"
                    id="crop_sub_type_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="5" lg="5">
                  <ValidationProvider name="Crop Name">
                    <b-form-group
                      class="row"
                      label-cols-sm="5"
                      label-for="crop_name_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('ministryDashboard.cropName')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.crop_name_id"
                      :options="cropNameData"
                      id="crop_name_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3">
                  <b-button class="float-left" type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('ministryDashboard.harvesting_report_list') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                            <b-button variant="primary" @click="pdfExport" class="mr-2">
                              {{  $t('globalTrans.export_pdf') }}
                            </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                              <b-row>
                                <b-col>
                                  <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="seeds/config/report-heading/detail" :org-id="2" :orgList="orgList">
                                    {{ $t('ministryDashboard.harvesting_report_list')}}
                                  </list-report-head>
                                </b-col>
                              </b-row>
                               <b-col>
                              </b-col>
                                <b-row mt-5>
                                  <b-table-simple bordered>
                                    <b-tr>
                                      <b-th class="text-center"> {{ $t('globalTrans.sl_no') }} </b-th>
                                      <b-th class="text-center"> {{ $t('cropInfo.cropName') }}</b-th>
                                      <b-th class="text-center"> {{ $t('seedsConfig.category') }}</b-th>
                                      <b-th class="text-center"> {{ $t('ministryDashboard.land_target') }}</b-th>
                                      <b-th class="text-center"> {{ $t('ministryDashboard.land_achievement') }}</b-th>
                                      <b-th class="text-center"> {{ $t('ministryDashboard.production_target_mton') }}</b-th>
                                      <b-th class="text-center"> {{ $t('ministryDashboard.harvesting') }}</b-th>
                                      <b-th class="text-center"> {{ $t('ministryDashboard.production_achievement_mton') }}</b-th>
                                    </b-tr>
                                    <template v-if="reportData.length">
                                        <template v-for="(cropData, index) in reportData" >
                                            <b-tr v-for="(info, index2) in cropData.details" :key="index + '_' +index2">
                                                <slot v-if="index2 === 0">
                                                    <b-td class="text-center align-middle" :rowspan="cropData.details.length" >{{ $n(index + 1) }}</b-td>
                                                    <b-td class="text-center align-middle" :rowspan="cropData.details.length" >
                                                        {{ currentLocale === 'en' ? info.crop_name : info.crop_name_bn }}
                                                    </b-td>
                                                </slot>
                                                <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.category_bn : info.category_en }}</b-td>
                                                <b-td class="text-center">{{ $n(info.land_target * 100000, { useGrouping: false }) }}</b-td>
                                                <b-td class="text-center">{{ $n(info.total_land_achievement * 100000, { useGrouping: false }) }} </b-td>
                                                <b-td class="text-center"> {{ $n(info.production_target * 100000, { useGrouping: false }) }} </b-td>
                                                <b-td class="text-center"> {{ $n(info.total_crop_harvesting, { useGrouping: false }) }} </b-td>
                                                <b-td class="text-center"> {{ $n(info.total_production_achievement, { useGrouping: false }) }} </b-td>
                                            </b-tr>
                                        </template>
                                      <tr>
                                        <td class="text-center" colspan="3"> {{ $t('ministryDashboard.total')}}</td>
                                        <td class="text-center">{{ $n(grantTotalObj.land_target, { useGrouping: false }) }}</td>
                                        <td class="text-center">{{ $n(grantTotalObj.total_land_achievement, { useGrouping: false }) }}</td>
                                        <td class="text-center">{{ $n(grantTotalObj.production_target, { useGrouping: false }) }}</td>
                                        <td class="text-center">{{ $n(grantTotalObj.total_crop_harvesting, { useGrouping: false }) }}</td>
                                        <td class="text-center">{{ $n(grantTotalObj.total_production_achievement, { useGrouping: false }) }}</td>
                                      </tr>
                                    </template>
                                    <template v-else>
                                        <b-tr>
                                            <b-td colspan="8" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                        </b-tr>
                                    </template>
                                  </b-table-simple>
                                </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { harvestReport } from '../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadMinister.vue'
import Pdf from './HarvestingReport'
export default {
  name: 'UiDataTable',
  props: ['fiscalYearList', 'currentFiscalYearId'],
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      search: {
        fiscal_year_id: 0,
        production_season_id: 0,
        crop_type_id: 0,
        crop_sub_type_id: 0,
        crop_name_id: 0
      },
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      CropTypeList: [],
      cropSubTypeList: [],
      cropNameList: [],
      productionSeasonList: [],
      dataLoading: false,
      reportData: [],
      orgList: [],
      grantTotalObj: {
        land_target: 0,
        total_land_achievement: 0,
        production_target: 0,
        total_crop_harvesting: 0,
        total_production_achievement: 0
      },
      loadingData: false
    }
  },
  created () {
    this.getCommonDropdownData()
    this.getOrgLIst()
    this.search.fiscal_year_id = this.currentFiscalYearId
  },
  mounted () {
    core.index()
  },
  computed: {
    cropTypeData: function () {
      const cropData = this.CropTypeList
      const tempData = cropData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    productionSeasonData: function () {
      const seasons = this.productionSeasonList.filter(item => item.status === 1)
      const tmpData = seasons.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tmpData
    },
    cropSubTypeData: function () {
      const subTypes = this.cropSubTypeList
      const tmpData = subTypes.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tmpData
    },
    cropNameData: function () {
      const cropNames = this.cropNameList
      const tmpData = cropNames.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tmpData
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    currentFiscalYearId: function (newVal, oldVal) {
      this.search.fiscal_year_id = newVal
    },
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.crop_type_id': function (newVal, oldVal) {
      if (newVal) {
        this.cropNameList = this.getCropName(newVal)
        this.cropSubTypeList = this.getCropSubType(newVal)
      }
    }
  },
  methods: {
  getTotalLand (datas) {
    let totalLandAmount = 0
    let totCropHarvesting = 0
    let productionTarget = 0
    let totalProductionAchievement = 0
    let totalLandAchievement = 0

    datas.forEach(item => {
      item.details.forEach(detail => {
        totalLandAmount += parseFloat(detail.land_target)
        totCropHarvesting += parseFloat(detail.total_crop_harvesting)
        productionTarget += parseFloat(detail.production_target)
        totalProductionAchievement += parseFloat(detail.total_production_achievement)
        totalLandAchievement += parseFloat(detail.total_land_achievement)
      })
    })
    this.grantTotalObj.land_target = totalLandAmount * 100000
    this.grantTotalObj.total_crop_harvesting = totCropHarvesting
    this.grantTotalObj.production_target = productionTarget * 100000
    this.grantTotalObj.total_production_achievement = totalProductionAchievement
    this.grantTotalObj.total_land_achievement = totalLandAchievement * 100000
    },
    searchData () {
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    async loadData () {
      this.reportData = []
      this.loadingData = true
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search)
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, harvestReport, params)
        if (result.success) {
            this.reportData = result.data
            this.getTotalLand(result.data)
        } else {
          this.reportData = []
        }
      this.loadingData = false
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async getOrgLIst () {
      await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', {}).then(response => {
        if (response.success) {
          this.orgList = response.data.orgList
        }
      })
    },
    async getCommonDropdownData () {
      this.dataLoading = true
      await RestApi.getData(seedFertilizerServiceBaseUrl, 'ministry-crop-dropdown', {}).then(response => {
        if (response.success) {
          this.CropTypeList = this.getCommonFormat(response.data.CropTypeList)
          this.CropNameList = this.getCommonFormat(response.data.CropNameList)
          this.seedSubTypeList = this.getCommonFormat(response.data.seedSubTypeList)
          this.productionSeasonList = this.getCommonFormat(response.data.productionSeasonList)
          this.seedCategoryList = this.getCommonFormat(response.data.seedCategoryList)
        }
      })
      this.dataLoading = false
    },
    getCommonFormat (data) {
      return data.map(item => {
        const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    getCropSubType (id) {
        return this.seedSubTypeList.filter(item => item.status === 1 && item.crop_type_id === id)
    },
    getCropName (id) {
      return this.CropNameList.filter(item => item.status === 1 && item.crop_type_id === id)
    },
    getSpecificCropName (ID) {
      const cropNameObj = this.CropNameList.find(item => item.value === ID)
      return cropNameObj !== undefined ? this.currentLocale === 'en' ? cropNameObj.text_en : cropNameObj.text_bn : ''
    },
    pdfExport () {
        const reportTitle = this.$t('ministryDashboard.harvesting_report_list')
        Pdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', 2, reportTitle, this.reportData, this, this.grantTotalObj, this.search)
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
