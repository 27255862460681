<template>
    <div>
      <b-overlay :show="loadSearch">
          <b-row>
            <b-col sm="3">
              <div class="license_card_item_wrapper production_wrapper">
                <div class="item_1">
                  <img src="../../../../../assets/images/ministry-dashboard/complited.svg" class="item_icon img-fluid">
                  <h2 class="count production_count">{{$n(dashboardData.totalCompleteResearch)}}</h2>
                </div>
                <div class="item_2 total">
                  <p class="title">{{ $t('research_publication.completed_research') }}</p>
                </div>
              </div>
            </b-col>
              <b-col sm="3">
              <div class="license_card_item_wrapper allocation_wrapper">
                <div class="item_1">
                  <img src="../../../../../assets/images/ministry-dashboard/ongoing.svg" class="item_icon img-fluid">
                  <h2 class="count allocation_count">{{ $n(dashboardData.totalOngoingResearch) }}</h2>
                </div>
                <div class="item_2 approved">
                  <p class="title">{{ $t('research_publication.ongoing_research') }}</p>
                </div>
              </div>
              </b-col>
              <b-col sm="3">
              <div class="license_card_item_wrapper sale_wrapper">
                <div class="item_1">
                  <img src="../../../../../assets/images/ministry-dashboard/Publication.svg" class="item_icon img-fluid">
                  <h2 class="count sale_count">{{ $n(dashboardData.totalPublication) }}</h2>
                </div>
                <div class="item_2 pending">
                  <p class="title">{{ $t('research_publication.total_publication') }}</p>
                </div>
              </div>
              </b-col>
              <b-col sm="3">
              <div class="license_card_item_wrapper stock_wrapper">
                <div class="item_1">
                  <img src="../../../../../assets/images/ministry-dashboard/crop-data.svg" class="item_icon img-fluid">
                  <h2 class="count stock_count">{{ $n(dashboardData.totalCropData) }}</h2>
                </div>
                <div class="item_2 process">
                  <p class="title">{{ $t('research_publication.total_crop') }}</p>
                </div>
              </div>
              </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <div class="grapChart_wrapper">
                <h5 class="card_title">{{ $t('research_publication.fiscal_wise_research_status') }}</h5>
                  <b-overlay>
                    <apexchart class="chart-1 text-center" id="vuechart-example" type="bar" height="250" :options="dataOption" :series="series"></apexchart>
                  </b-overlay>
                <!-- <img src="../../../../../assets/images/machineries-barChart-trainee.jpg" class="w-100 img-fluid" alt=""> -->
              </div>
            </b-col>
            <b-col sm="6">
              <div class="grapChart_wrapper">
                <h5 class="card_title">{{ $t('research_publication.funding_status') }}</h5>
                <!-- <img src="../../../../../assets/images/basic-line-chart.png" class="w-100 img-fluid" alt=""> -->
                <b-overlay>
                  <apexchart class="chart-1 text-center" id="vuechart-example" type="line" height="250" :options="dataOptionLine" :series="seriesBar"></apexchart>
                </b-overlay>
              </div>
            </b-col>
          </b-row>
          <div class="text-right">
            <router-link :to="{ name: 'authSignUp.dashboard', params: {dashboardUrl: '/agri-research-service/research-management/activity-progress-report' }}" class="details_btn inc_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
          </div>
      </b-overlay>
    </div>
</template>
<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'

export default {
  name: 'UiDataTable',
  data () {
    return {
      loadSearch: true,
      search: {},
      dashboardData: {},
      seriesData: [],
      labelList: [],
      fundSeriesData: [],
      fundLabelList: []
    }
  },
  computed: {
    series: function () {
      return [{
        name: this.$t('sitePreference.total'),
        data: this.seriesData
      }]
    },
    seriesBar: function () {
      return [{
        name: this.$t('sitePreference.total'),
        data: this.fundSeriesData
      }]
    },
    dataOption () {
      return {
        chart: {
          id: 'vuechart-example',
          type: 'bar'
        },
        plotOptions: {
          bar: {
            barHeight: '50px',
            columnWidth: '35%',
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: 'bottom',
              horizontalAlign: 'center'
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -1,
          style: {
            fontSize: '14px',
            colors: ['#fff']
          }
        },
        labels: this.labelList,
        xaxis: {
            title: {
              text: this.$t('globalTrans.fiscal_year')
            }
        },
        yaxis: {
            title: {
              text: this.$t('globalTrans.count')
            }
        }
      }
    },
    dataOptionLine () {
        return {
            chart: {
                id: 'vuechart-example',
                type: 'line'
            },
            plotOptions: {
                line: {
                lineHeight: '50px',
                columnWidth: '40%',
                distributed: true,
                horizontal: false,
                dataLabels: {
                    position: 'bottom',
                    horizontalAlign: 'center'
                }
                }
            },
            labels: this.fundLabelList,
            xaxis: {
                title: {
                text: this.$t('globalTrans.fiscal_year')
                }
            },
            yaxis: {
                title: {
                text: this.$t('globalTrans.amount')
                }
            }
        }
      }
  },
  async created () {
    this.loadData()
  },
  methods: {
    setCurrentFiscalYear (fiscalYearList, date) {
      if (fiscalYearList.length === 0) {
          return
      }
      const currentDate = new Date(date)
      const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
      /** Define the year position  */
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      let currentFiscalYearId = 0
      fiscalYearList.forEach(element => {
          if (element.text_en.indexOf(yearStr) === yearPosition) {
              currentFiscalYearId = element.value
          }
      })
      return currentFiscalYearId
    },
    async loadData () {
        this.loadSearch = true
        await RestApi.getData(agriResearchServiceBaseUrl, 'ministry-dashboard/list', this.search).then(response => {
        if (response.success) {
          this.dashboardData = response.data
          this.getSeriesAndLabel(this.dashboardData.researchData)
          this.getSeriesAndLabel2(this.dashboardData.fundData)
        }
        this.loadSearch = false
      })
    },
    getSeriesAndLabel (data) {
        const listData = data.map(item => {
            const fiscalYear = this.$store.state.commonObj.fiscalYearList
            const fiscalId = this.setCurrentFiscalYear(fiscalYear, item.created_at)
            const newData = { fiscal_year_id: fiscalId }
            return Object.assign({}, item, newData)
        })
        this.$store.state.commonObj.fiscalYearList.map(el => {
            const count = listData.filter(itm => itm.fiscal_year_id === el.value)
            if (count.length > 0) {
              this.seriesData.push(count.length)
              this.labelList.push(this.$i18n.locale === 'bn' ? el.text_bn : el.text_en)
            }
        })
    },
    getSeriesAndLabel2 (data) {
        const listData = data.map(item => {
            const fiscalYear = this.$store.state.commonObj.fiscalYearList
            const fiscalId = this.setCurrentFiscalYear(fiscalYear, item.created_at)
            const newData = { fiscal_year_id: fiscalId }
            return Object.assign({}, item, newData)
        })
        this.$store.state.commonObj.fiscalYearList.map(el => {
            const count = listData.filter(itm => itm.fiscal_year_id === el.value)
            let sum = 0
            if (count.length > 0) {
              count.map(newItm => {
                sum = sum + parseFloat(newItm.total_amount)
              })
              this.fundSeriesData.push(sum)
              this.fundLabelList.push(this.$i18n.locale === 'bn' ? el.text_bn : el.text_en)
            }
        })
    }
  }
}
</script>
