<template>
  <div>
    <b-overlay :show="loading">
      <b-row class="justify-content-center">
        <b-col sm="3">
          <div class="card_item_wrapper production_wrapper">
              <div class="number_wrapper">
              <p class="number">
                  <img src="../../../../../assets/images/ministry-dashboard/pump.svg" class="item_icon img-fluid">
              </p>
              </div>
              <div class="card_item">
              <p class="title">{{ $t('ministryDashboard.totalInstalledPump') }}</p>
              <h2 class="count production_count">{{ $n(data.total_installed_pump) }}</h2>
              </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="card_item_wrapper allocation_wrapper">
              <div class="number_wrapper">
              <p class="number">
                  <img src="../../../../../assets/images/ministry-dashboard/smart-card.svg" class="item_icon img-fluid">
              </p>
              </div>
              <div class="card_item">
              <p class="title">{{ $t('ministryDashboard.totalSmartCard') }}</p>
              <h2 class="count allocation_count">{{ $n(data.total_smart_card) }}</h2>
              </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="card_item_wrapper sale_wrapper">
              <div class="number_wrapper">
              <p class="number">
                  <img src="../../../../../assets/images/ministry-dashboard/application-w.svg" class="item_icon img-fluid">
              </p>
              </div>
              <div class="card_item">
              <p class="title">{{ $t('ministryDashboard.schemeApplication') }}</p>
              <h2 class="count sale_count">{{ $n(data.total_scheme_app) }}</h2>
              </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="card_item_wrapper stock_wrapper">
              <div class="number_wrapper">
              <p class="number">
                  <img src="../../../../../assets/images/ministry-dashboard/approval-application-w.svg" class="item_icon img-fluid">
              </p>
              </div>
              <div class="card_item">
              <p class="title">{{ $t('ministryDashboard.approvedScheme') }}</p>
              <h2 class="count stock_count">{{ $n(data.total_scheme_approved) }}</h2>
              </div>
          </div>
        </b-col>
      </b-row>
      <div class="text-right">
        <router-link :to="{ name: 'authSignUp.dashboard', params: { dashboardUrl: '/irrigation-scheme-service/pump-installation/irrigation-farmer' }}" class="details_btn seed_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { ministryDashboard } from '../../api/routes'

export default {
    name: 'IrrigationDashboard',
    data () {
      return {
        loading: false,
        data: {}
      }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      }
    },
    created () {
      this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
        async loadData () {
            this.loading = true
            await RestApi.getData(irriSchemeServiceBaseUrl, ministryDashboard)
            .then(response => {
                if (response.success) {
                    this.data = response.data
                } else {
                    this.data = {}
                }
                this.loading = false
            })
        }
    }
}
</script>
