<template>
  <div>
    <b-overlay :show="loadSearch">
      <TopBar :search="search" :importData="importData" :allocation="allocation" :delivery="delivery" :currentStock="currentStock"/>
      <iq-card v-if="(search.fertilizer_type === 2 || search.fertilizer_type === 1) || (search.fertilizer_type === 3 && search.type === 1)">
        <template v-slot:body>
          <b-row>
            <b-col sm="1"></b-col>
            <b-col sm="10">
              <table class="table table-hover table-bordered movement-table" style="width: 100% !important">
                <thead style="background: #00bbd4;color: #fff;">
                  <tr>
                    <th scope="col">{{ $t('globalTrans.sl_no') }}</th>
                    <th scope="col">{{ $t('fertilizerConfig.fertilizer_name') }} </th>
                    <th scope="col">
                      <slot v-if="(search.fertilizer_type === 2 || search.fertilizer_type === 1) || (search.fertilizer_type === 3 && search.type === 1)">
                        {{ $t('ministryDashboard.import') }}
                        <span>({{ $t('globalTrans.m_ton') }})</span>
                      </slot>
                      <slot v-else>
                        {{ $t('ministryDashboard.production') }}
                      </slot>
                    </th>
                    <th scope="col">{{ $t('ministryDashboard.allocation') }}</th>
                    <th scope="col">{{ $t('ministryDashboard.distribution') }} ({{ $t('globalTrans.m_ton') }})</th>
                    <th scope="col">{{ $t('ministryDashboard.stock') }}</th>
                  </tr>
                </thead>
                <tbody style="background: #f5f5f5;">
                  <slot v-if="fertilizers.length>0">
                    <b-tr  v-for="(fertilizer, index) in fertilizers" :key="index">
                      <th scope="row">{{$n(index+1)}}</th>
                      <td>{{ $i18n.locale === 'bn' ?  fertilizer.fertilizer_name_bn : fertilizer.fertilizer_name }}</td>
                      <td>{{$n(fertilizer.import, { useGrouping: false })}}</td>
                      <td>{{$n(fertilizer.ministryData, { useGrouping: false })}}</td>
                      <td>{{$n(fertilizer.todayDelivery, { useGrouping: false })}}</td>
                      <td>{{$n(fertilizer.currentStock, { useGrouping: false })}}</td>
                    </b-tr>
                  </slot>
                  <slot v-else>
                    <b-tr>
                      <th colspan="6" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                    </b-tr>
                  </slot>
                </tbody>
              </table>
            </b-col>
            <b-col sm="1"></b-col>
          </b-row>
        </template>
      </iq-card>
      <b-row>
        <slot v-if="(search.fertilizer_type === 2 || search.fertilizer_type === 1) || (search.fertilizer_type === 3 && search.type === 1)">
          <b-col sm="6">
            <div class="grapChart_wrapper" style="height: 440px">
              <h5 class="card_title">{{ $t('ministryDashboard.import_allocation_distribution_status') }}</h5>
              <apexchart height="300"  type="line" v-if="prodCheck" :options="lineOption" :series="lineSeriesData"></apexchart>
            </div>
          </b-col>
          <b-col sm="6">
            <div class="grapChart_wrapper" style="height: 440px">
              <h5 class="card_title">{{ $t('fertilizerConfig.country_wise_fertililzer_status') }}</h5>
              <apexchart height="300" id="Chart" class="chart-1 text-center px-5" v-if="searchThis && search.fertilizer_type === 1" type="pie" :options="spaceAvailableDashboardLabels" :series="series()"></apexchart>
              <apexchart height="300" id="Chart1" class="chart-1 text-center px-5" v-if="searchThis && search.fertilizer_type === 2" type="pie" :options="spaceAvailableDashboardLabels" :series="series()"></apexchart>
              <apexchart height="300" id="Chart2" class="chart-1 text-center px-5" v-if="searchThis && search.fertilizer_type === 3" type="pie" :options="spaceAvailableDashboardLabels" :series="series()"></apexchart>
            </div>
          </b-col>
        </slot>
        <slot v-else>
          <b-col sm="3"></b-col>
          <b-col sm="6">
            <div class="grapChart_wrapper" style="height: 440px">
              <h5 class="card_title">{{ $t('ministryDashboard.production_allocation_distribution_status') }}</h5>
              <apexchart height="300" type="line" v-if="prodCheck" :options="lineOption" :series="lineSeriesData"></apexchart>
            </div>
          </b-col>
        </slot>
      </b-row>
      <div class="text-right">
        <router-link :to="{ name: 'authSignUp.dashboard', params: {dashboardUrl: '/seeds-fertilizer-service/fertilizer-reports/current-stock-report' }}" class="details_btn seed_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import TopBar from './TopBar'
export default {
  name: 'UiDataTable',
  components: {
    TopBar
  },
  props: ['search', 'currentFiscalYearId'],
  data () {
    return {
      loadSearch: false,
      importData: 0,
      allocation: 0,
      delivery: 0,
      currentStock: 0,
      datas: [],
      monthlyWiseImport: [],
      searchThis: false,
      prodCheck: false,
      loading: false,
      importMonthValues: [],
      ministryDataMonthValues: [],
      deliveryValues: [],
      lineData: [],
      seriesData: [0, 0, 0],
      chartOptions: {
        chart: {
          width: 200,
          height: 320,
          type: 'pie'
        },
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      lineSeriesData: [],
      monthImportBcic: [],
      nonUria: [],
      uria: [],
      firstLabel: [],
      firstLabelBn: [],
      fertilizerType: 1,
      fertilizers: []
    }
  },
  computed: {
    monthList: function () {
      const dashboardMonthList = [
        {
          value: 7,
          text_en: 'Jul',
          text_bn: 'জুলাই'
        },
        {
          value: 8,
          text_en: 'Aug',
          text_bn: 'আগস্ট'
        },
        {
          value: 9,
          text_en: 'Sept',
          text_bn: 'সেপ্টে'
        },
        {
          value: 10,
          text_en: 'Oct',
          text_bn: 'অক্টো'
        },
        {
          value: 11,
          text_en: 'Nov',
          text_bn: 'নভে'
        },
        {
          value: 12,
          text_en: 'Dec',
          text_bn: 'ডিসে'
        },
        {
          value: 1,
          text_en: 'Jan',
          text_bn: 'জানু'
        },
        {
          value: 2,
          text_en: 'Feb',
          text_bn: 'ফেব্রু'
        },
        {
          value: 3,
          text_en: 'March',
          text_bn: 'মার্চ'
        },
        {
          value: 4,
          text_en: 'Apr',
          text_bn: 'এপ্রিল'
        },
        {
          value: 5,
          text_en: 'May',
          text_bn: 'মে'
        },
        {
          value: 6,
          text_en: 'June',
          text_bn: 'জুন'
        }
      ]
      return dashboardMonthList.map(item => {
        const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    fertilizerTypeOptions () {
      return [
        // { text: this.$t('globalTrans.all'), value: 1 },
        { text: this.$t('globalTrans.badc'), value: 1 },
        { text: this.$t('globalTrans.bcic'), value: 2 }
      ]
    },
     yearList: function () {
      var max = new Date().getFullYear()
      var min = max - 31
      var years = []
      for (var i = max; i >= min; i--) {
        const yearData = {}
        yearData.value = i
        yearData.text = this.$i18n.locale === 'bn' ? this.$n(i, { useGrouping: false }) : i
        yearData.text_en = i
        yearData.text_bn = i
        years.push(yearData)
      }
      return years
      // return this.$store.state.SeedsFertilizer.commonObj.yearList
    },
    dataOption () {
      return {
        chart: {
          id: 'vuechart-example',
          height: 320,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            barHeight: '50px',
            columnWidth: '40%',
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: 'bottom',
              horizontalAlign: 'center'
            }
          }
        },
        labels: [
          this.$t('months.jul'),
          this.$t('months.aug'),
          this.$t('months.sep'),
          this.$t('months.oct'),
          this.$t('months.nov'),
          this.$t('months.dec'),
          this.$t('months.jan'),
          this.$t('months.feb'),
          this.$t('months.mar'),
          this.$t('months.apr'),
          this.$t('months.may'),
          this.$t('months.jun')
        ]
      }
    },
    spaceAvailableDashboardLabels () {
      return {
        chart: {
          width: 350,
           height: 320,
          type: 'pie'
        },
        labels: this.$i18n.locale === 'bn' ? this.firstLabelBn : this.firstLabel,
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 350
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },
    regionList: function () {
        return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    lineOption () {
      return {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        categories: this.monthList.map(item => {
            return this.$i18n.locale === 'bn' ? item.text_bn : item.text_en
        })
      },
      grid: {
        borderColor: '#f1f1f1'
        }
      }
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  async created () {
    // this.loadData()
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setLineSeriesData()
        this.translateFiscalYear()
      }
    },
    currentFiscalYearId: function (newValue) {
      if (newValue > 0) {
        this.loadData()
      }
    },
    'search.fertilizer_type': function (newValue) {
      this.search.type = 1
      this.loadData()
    },
    'search.type': function (newValue) {
      this.loadData()
    }
  },
  methods: {
    monthCheck (value) {
      const d = new Date(value)
      const month = d.getMonth()
      return month + 1
    },
    series () {
      return this.seriesData
    },
    async loadData () {
      this.loadSearch = true
      this.searchThis = false
      await RestApi.getData(seedFertilizerServiceBaseUrl, 'fertilizer/dashboard/ministry-dashboard', this.search).then(response => {
        if (response.success) {
          this.importData = response.data.import
          this.allocation = response.data.ministryData
          this.delivery = response.data.delivery
          this.currentStock = response.data.currentStock
          this.fertilizers = response.data.fertilizers
          this.monthImportBcic = response.data.monthImportBcic
          this.uria = response.data.uria
          this.nonUria = response.data.nonUria
          this.datas = response.data
          const seriesData = []
          this.firstLabel = []
          this.firstLabelBn = []
          if (this.datas.countryWiseImport.length > 0) {
            this.datas.countryWiseImport.forEach((data, key) => {
              seriesData.push(data.pecentage)
              // this.firstLabel.push(data.country_name + '(' + data.fertilizer_name + ')')
              // this.firstLabelBn.push(data.country_name_bn + '(' + data.fertilizer_name_bn + ')')
              this.firstLabel.push(data.country_name)
              this.firstLabelBn.push(data.country_name_bn)
            })
            this.seriesData = seriesData
            this.searchThis = true
          } else {
            this.seriesData = ['']
            this.firstLabel = ['']
            this.firstLabelBn = [100]
          }
        }
        this.getMonthData(response.data.importMonth, response.data.ministryDataMonth, response.data.deliveryMonth)
        this.loadSearch = false
      })
    },
    getMonthData (importMonth, ministryDataMonth, deliveryMonth) {
      this.importMonthValues = []
      this.ministryDataMonthValues = []
      this.deliveryValues = []
      let prevData = []
      if (importMonth.length > 0) {
        prevData = importMonth.map(item => {
          const monthData = this.monthCheck(item.lc_date)
          const monthAssignData = {
            month_id: parseInt(monthData)
          }
          return Object.assign({}, item, monthAssignData)
        })
      }
      this.monthList.map(item => {
        const importMonth = prevData.find(prevData => prevData.month_id === item.value)
        let quantity = 0
        if (typeof importMonth !== 'undefined') {
          quantity = importMonth.quantity
        }
        const ministryDataMonthList = ministryDataMonth.find(ministryDataMonth => parseInt(ministryDataMonth.month) === parseInt(item.value))
        let ministryQty = 0
        if (typeof ministryDataMonthList !== 'undefined') {
          ministryQty = ministryDataMonthList.quantity
        }
        const deliveryData = deliveryMonth.find(delivery => parseInt(delivery.month) === item.value)
        let deliveryQty = 0
        if (typeof deliveryData !== 'undefined') {
          deliveryQty = deliveryData.quantity
        }
        const importMonthBcic = this.monthImportBcic.find(monthImportBcic => monthImportBcic.month_id === item.value)
        const nonUria = this.nonUria.find(allMonthImportBcicOther => allMonthImportBcicOther.month_id === item.value)
        let nonQty = 0
        if (typeof nonUria !== 'undefined') {
          nonQty = nonUria.total_production
          quantity = quantity + nonUria.total_production
        }
        const uria = this.uria.find(allMonthImportBcicOtherS => allMonthImportBcicOtherS.month_id === item.value)
        let uriaQty = 0
        if (typeof uria !== 'undefined') {
          uriaQty = uria.total_production
          // quantity = quantity + uria.total_production
        }
        if (typeof importMonthBcic !== 'undefined') {
          if (this.search.fertilizer_type === 3) {
            if (this.search.type === 1) {
              quantity = nonQty
            } else {
              quantity = uriaQty
            }
          }
          // quantity = quantity + importMonthBcic.total_production
          ministryQty = ministryQty + importMonthBcic.total_allocation
          deliveryQty = deliveryQty + importMonthBcic.total_distribution
        }
        this.importMonthValues.push(quantity.toFixed(2))
        this.ministryDataMonthValues.push(ministryQty.toFixed(2))
        this.deliveryValues.push(deliveryQty.toFixed(2))
      })
      this.setLineSeriesData()
      this.prodCheck = true
    },
    proudctionMetrict (totalAmount) {
      return (totalAmount / 1000).toFixed(2)
    },
    allocationMetric (totalAmount) {
        return (totalAmount / 1000).toFixed(2)
    },
    ministryDataMonthsMetric (totalAmount) {
        return (totalAmount / 1000).toFixed(2)
    },
    stockMetric (totalAmount) {
        return (totalAmount / 1000).toFixed(2)
    },
    setLineSeriesData () {
        this.lineSeriesData = [
        {
          name: this.search.type === 1 ? this.$t('ministryDashboard.import') : this.$t('ministryDashboard.production'),
          data: this.importMonthValues
        },
        {
          name: this.$t('ministryDashboard.allocation'),
          data: this.ministryDataMonthValues
        },
        {
          name: this.$t('ministryDashboard.distribution'),
          data: this.deliveryValues
        }
      ]
    }
  }
}
</script>
