import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, grantTotalObj, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('ministryDashboard.cropName'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('seedsConfig.category'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('ministryDashboard.land_target'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('ministryDashboard.land_achievement'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('ministryDashboard.achievement_rate'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
          if (data.length > 0) {
            data.forEach((item, optionIndex) => {
              item.details.forEach((info1, index2) => {
                const NewLoop = []
                if (index2 === 0) {
                  NewLoop.push({ text: vm.$n(optionIndex + 1), style: 'td', rowSpan: item.details.length, alignment: 'center' })
                  NewLoop.push({ text: vm.$i18n.locale === 'bn' ? item.crop_name_bn : item.crop_name, style: 'td', rowSpan: item.details.length, alignment: 'center' })
                } else {
                  NewLoop.push({})
                  NewLoop.push({})
                }
                NewLoop.push({ text: (i18n.locale === 'bn') ? info1.category_bn : info1.category_en, alignment: 'center', style: 'td' })
                NewLoop.push({ text: vm.$n(info1.land_target * 100000, { useGrouping: false }), alignment: 'center', style: 'td' })
                NewLoop.push({ text: vm.$n(info1.total_land_achievement * 100000, { useGrouping: false }), alignment: 'center', style: 'td' })
                NewLoop.push({ text: info1.land_target && info1.total_land_achievement >= 0 ? vm.$n(info1.total_land_achievement * 100000 / (info1.land_target * 100000) * 100, { useGrouping: false }) : vm.$n(0), alignment: 'center', style: 'td' })
                allRows.push(NewLoop)
              })
            })
            allRows.push(
                  [
                    { text: vm.$t('ministryDashboard.total'), colSpan: '3', style: 'td', alignment: 'center' },
                    {},
                    {},
                    { text: vm.$n(grantTotalObj.land_target), alignment: 'center', style: 'td' },
                    { text: vm.$n(grantTotalObj.total_land_achievement), alignment: 'center', style: 'td' },
                    { text: vm.$n(grantTotalObj.total_land_achievement / (grantTotalObj.land_target) * 100), alignment: 'center', style: 'td' }
                  ]
              )
           } else {
              allRows.push(
                [
                  { text: vm.$t('globalTrans.noDataFound'), colSpan: '6', style: 'td', alignment: 'center' },
                  {},
                  {},
                  {},
                  {},
                  {}
                ]
            )
          }
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['10%', '20%', '20%', '20%', '20%', '10%'],
              body: allRows
            }
          })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
