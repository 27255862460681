<template>
    <div>
      <iq-card>
        <template v-slot:body>
          <div class="org_filter_wrapper">
            <b-row>
              <b-col sm="10" md="10" xl="10" lg="10" offset="1">
                <b-form-group>
                  <b-form-radio-group
                    id="btn-radios-1"
                    v-model="search.crop_type"
                    :options="cropTypeOptions"
                    name="radios-btn-default"
                    buttons
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <template v-if="search.crop_type === 1">
                <cultivationInfoReport :currentFiscalYearId="currentFiscalYearId" :fiscalYearList="fiscalYearList"/>
              </template>
              <template v-if="search.crop_type === 2">
                <harvestingInfoReport :currentFiscalYearId="currentFiscalYearId" :fiscalYearList="fiscalYearList"/>
              </template>
              <template v-if="search.crop_type === 3">
                <landProductionAchievementReport :currentFiscalYearId="currentFiscalYearId" :fiscalYearList="fiscalYearList"/>
              </template>
            </b-row>
          </div>
        </template>
      </iq-card>
      <br/>
    </div>
  </template>
  <script>
  import cultivationInfoReport from './crop-report/CultivationInfoReport'
  import harvestingInfoReport from './crop-report/HarvestingInfoReport'
  import landProductionAchievementReport from './crop-report/LandProductionAchievementReport'
  export default {
    name: 'UiDataTable',
    props: ['fiscalYearList', 'currentFiscalYearId'],
    components: {
      cultivationInfoReport, harvestingInfoReport, landProductionAchievementReport
    },
    data () {
      return {
        search: {
          crop_type: 1
        }
      }
    },
    created () {
    },
    computed: {
      cropTypeOptions () {
        return [
          { text: this.$t('ministryDashboard.cultivation_info_report'), value: 1 },
          { text: this.$t('ministryDashboard.harvesting_report'), value: 2 },
          { text: this.$t('ministryDashboard.land_production_achievement'), value: 3 }
        ]
      }
    }
  }
  </script>
  <style scope>
    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle{
      background-color: #228b21 !important;
      border-color: #3db043 !important;
    }
  </style>
