<template>
  <div>
    <b-overlay :show="loadChart">
      <b-row class="justify-content-center">
        <b-col sm="3">
          <div class="license_card_item_wrapper sale_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/total-license-b.svg" class="item_icon img-fluid">
              <h2 class="count sale_count">{{ $n(chart.total_service) }}</h2>
            </div>
            <div class="item_2 pending">
              <p class="title">{{ $t('ministryDashboard.total_lrcpn') }}</p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <div class="license_card_item_wrapper production_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/total-application-o.svg" class="item_icon img-fluid">
              <h2 class="count production_count">{{ $n(chart.total_application) }}</h2>
            </div>
            <div class="item_2 total">
              <p class="title">{{ $t('ministryDashboard.total_appllication') }}</p>
            </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="license_card_item_wrapper allocation_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/total-application.svg" class="item_icon img-fluid">
              <h2 class="count production_count">{{ $n(chart.pending_application) }}</h2>
            </div>
            <div class="item_2 approved">
              <p class="title">{{ $t('ministryDashboard.pending_application') }}</p>
            </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="license_card_item_wrapper sale_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/total-application-b.svg" class="item_icon img-fluid">
              <h2 class="count production_count">{{ $n(chart.approved_application) }}</h2>
            </div>
            <div class="item_2 pending">
              <p class="title">{{ $t('ministryDashboard.approved_application') }}</p>
            </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="license_card_item_wrapper stock_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/painding-application.svg" class="item_icon img-fluid">
              <h2 class="count production_count">{{ $n(chart.rejected_application) }}</h2>
            </div>
            <div class="item_2 process">
              <p class="title">{{ $t('ministryDashboard.rejected_application') }}</p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col sm="12">
          <div class="grapChart_wrapper" style="height: auto !important">
            <h5 class="card_title">{{ $t('ministryDashboard.license_status') }}</h5>
            <div v-if="loadChart"></div>
            <div v-else id="chart">
              <apexchart height="400" :options="chartOptions" :series="series"></apexchart>
            </div>
          </div>
        </b-col>
      </b-row>
        <div class="text-right"><router-link :to="{ name: 'authSignUp.dashboard', params: { dashboardUrl: '/license-registration-service/license-step/reports/808' }}" class="details_btn inc_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { lisenceDashboardData } from '../../api/routes'

export default {
    data () {
      return {
        loadChart: false,
        chartServices: [],
        chart: {
          total_service: 0,
          total_application: 0,
          pending_application: 0,
          approved_application: 0,
          rejected_application: 0
        },
        series: [{
          data: []
        }],
        chartOptions: {
          chart: {
            type: 'bar'
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              dataLabels: {
                position: 'top'
              }
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: -6
          },
          xaxis: {
            categories: []
          }
        }
      }
    },
    computed: {
      local: function () {
          return this.$i18n.locale
      }
    },
    watch: {
      local: function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.getLanguateWiseData()
          }
      }
    },
    created () {
      this.loadData()
    },
    methods: {
        async loadData () {
          this.loadChart = true
          const response = await RestApi.getData(licenseRegistrationServiceBaseUrl, lisenceDashboardData)
          this.loadChart = false
          if (response.success) {
              this.chart = response.data
              this.chartServices = response.services
              response.services.forEach(item => {
                this.series[0].data.push(item.total)
                const serName = this.local === 'bn' ? item.service_name_bn : item.service_name
                this.chartOptions.xaxis.categories.push(serName)
              })
          } else {
              this.chart = {}
          }
        },
        async getLanguateWiseData () {
          this.loadChart = true
          this.series[0].data = []
          this.chartOptions.xaxis.categories = []
          await this.chartServices.forEach(item => {
            this.series[0].data.push(item.total)
            const serName = this.$i18n.locale === 'bn' ? item.service_name_bn : item.service_name
            this.chartOptions.xaxis.categories.push(serName)
          })
          this.loadChart = false
        },
        formatText (data, min) {
          if (data.length > min) {
            return data.substring(0, min) + '...'
          }
          return data
        }
    }
}
</script>
