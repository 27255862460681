<template>
  <div class="ministry_wrapper" v-bind:style="styleObject">
    <div v-if="ministryPanelText.length !== 0"><br/></div>
    <b-container fluid>
      <div class="btn_all_component">
        <router-link :to="{ path: '/auth/dashboard' }"  class="all_component">
          <i class="fas fa-reply-all"></i> {{ $t('ministryDashboard.components') }}
        </router-link>
      </div>
      <!-- Accordion Start -->
      <b-row>
        <b-col sm="12">
          <div class="accordion" role="tablist">
            <!-- Fertilizer Wrapper -->
            <div class="accordion-item fertilizer_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="fertilizer">
                <b-button block v-b-toggle.fertilizer class="accordion-button accordion_warehouse">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/fartilizer.svg" class="item_icon">
                    <span class="title title_fertilizer">{{ dashboardNames.fertilizer }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="fertilizer" visible role="tabpanel">
                <div class="accordion-body fertilizer">
                  <Fertilizer :currentFiscalYearId="currentFiscalYearId" :fiscalYearList="fiscalYearList"></Fertilizer>
                </div>
              </b-collapse>
            </div>
            <!-- Seed Wrapper -->
            <div class="accordion-item seed_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="seed">
                <b-button block v-b-toggle.seed class="accordion-button accordion_seed">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/seed.svg" class="item_icon">
                    <span class="title title_seed">{{ dashboardNames.seed }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="seed" visible role="tabpanel">
                <div class="accordion-body seed">
                  <Seed></Seed>
                </div>
              </b-collapse>
            </div>
            <!-- Crop Wrapper-->
            <div class="accordion-item crop_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="crop">
                <b-button block v-b-toggle.crop class="accordion-button accordion_seed">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/seed.svg" class="item_icon">
                    <span class="title title_seed">{{ dashboardNames.crop }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="crop" visible role="tabpanel">
                <div class="accordion-body seed">
                  <Crop :currentFiscalYearId="currentFiscalYearId" :fiscalYearList="fiscalYearList"></Crop>
                </div>
              </b-collapse>
            </div>
            <!-- Incentive Wrapper -->
            <div class="accordion-item incentive_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="incentive">
                <b-button block v-b-toggle.incentive class="accordion-button accordion_incentive">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/incentive.svg" class="item_icon">
                    <span class="title title_incentive">{{ dashboardNames.incentive }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="incentive" visible role="tabpanel">
                <div class="accordion-body incentive">
                  <Incentive :currentFiscalYearId="currentFiscalYearId" :fiscalYearList="fiscalYearList"></Incentive>
                </div>
              </b-collapse>
            </div>
            <!-- License Wrapper -->
            <div class="accordion-item license_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="license">
                <b-button block v-b-toggle.license class="accordion-button accordion_license">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/license.svg" class="item_icon">
                    <span class="title title_seed">{{ dashboardNames.license }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="license" visible role="tabpanel">
                <div class="accordion-body license">
                  <License></License>
                </div>
              </b-collapse>
            </div>
            <!-- Warehouse Wrapper -->
            <div class="accordion-item warehouse_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="warehouse">
                <b-button block v-b-toggle.warehouse class="accordion-button accordion_warehouse">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/warehouse-w.svg" class="item_icon">
                    <span class="title title_seed">{{ dashboardNames.warehouse }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="warehouse" visible role="tabpanel">
                <div class="accordion-body warehouse">
                  <Warehouse></Warehouse>
                </div>
              </b-collapse>
            </div>
            <!-- Training Wrapper -->
            <div class="accordion-item incentive_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="training">
                <b-button block v-b-toggle.training class="accordion-button accordion_incentive">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/training.svg" class="item_icon">
                    <span class="title title_incentive">{{ dashboardNames.training }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="training" visible role="tabpanel">
                <div class="accordion-body training">
                  <Training></Training>
                </div>
              </b-collapse>
            </div>
            <!-- Research Wrapper -->
            <div class="accordion-item license_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="research">
                <b-button block v-b-toggle.research class="accordion-button accordion_license">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/research.svg" class="item_icon">
                    <span class="title title_seed">{{ dashboardNames.research }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="research" visible role="tabpanel">
                <div class="accordion-body research">
                  <ResearchPublications></ResearchPublications>
                </div>
              </b-collapse>
            </div>
            <!-- Irrigation Wrapper -->
            <div class="accordion-item fertilizer_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="irrigation">
                <b-button block v-b-toggle.irrigation class="accordion-button accordion_seed">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/irrigation.svg" style="transform: rotate3d(0, 1, 0, -180deg);" class="item_icon">
                    <span class="title title_fertilizer">{{ dashboardNames.irrigation }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="irrigation" visible role="tabpanel">
                <div class="accordion-body irrigation">
                  <Irrigation></Irrigation>
                </div>
              </b-collapse>
            </div>
            <!-- Movement Wrapper -->
            <div class="accordion-item incentive_wrapper">
              <h2 class="accordion-header" header-tag="header" v-bind:style="styleObject" ref="movement">
                <b-button block v-b-toggle.movement class="accordion-button accordion_incentive">
                  <div class="header_inner">
                    <img src="../../../../../assets/images/ministry-dashboard/movement.svg" class="item_icon">
                    <span class="title title_fertilizer">{{ dashboardNames.movement }}</span>
                  </div>
                </b-button>
              </h2>
              <b-collapse id="movement" visible role="tabpanel">
                <div class="accordion-body movement" style="background: #eee">
                  <Movement></Movement>
                </div>
              </b-collapse>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- Accordion End -->
    </b-container>
  </div>
</template>
<script>
  import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
  import { mapGetters } from 'vuex'
  import Seed from './Seed'
  import Crop from './Crop'
  import Incentive from './Incentive'
  import Warehouse from './Warehouse'
  import License from './License'
  import Fertilizer from './Fertilizer'
  import Training from './Training'
  import ResearchPublications from './ResearchPublications'
  import Irrigation from './Irrigation'
  import Movement from './Movement'
  import { EventBus } from '@/EventBusLayout'

  export default {
    name: 'MinistryDashboard',
    components: {
      Seed, Incentive, Warehouse, License, Fertilizer, Training, ResearchPublications, Irrigation, Movement, Crop
    },
    data () {
      return {
        styleObject: {
          scrollMarginTop: '-220px'
        },
        dashboardNames: {
          seed: '',
          fertilizer: '',
          license: '',
          irrgigation: '',
          incentive: '',
          research: '',
          training: '',
          warehouse: '',
          crop: '',
          movement: ''
        },
        currentFiscalYearId: 0,
        fiscalYearListData: []
      }
    },
    computed: {
      ...mapGetters({
        authUser: 'Auth/authUser',
        defaultNavbarHeight: 'Setting/getDefaultNavbarHeight',
        ministryPanelText: 'Setting/getMinistryPanelText'
      }),
      fiscalYearList: function () {
        const listObject = this.fiscalYearListData
        const tmpList = listObject.map((obj, index) => {
          // if (this.$i18n.locale === 'bn') {
          //     return { value: obj.value, text: obj.text_bn }
          // } else {
          //     return { value: obj.value, text: obj.text_en }
          // }
          return Object.assign({}, obj, { text: this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en })
        })
        return tmpList
      }
    },
    watch: {
      defaultNavbarHeight: function (newVal, oldVal) {
        this.setPageTopMargin(newVal, oldVal)
      },
      $route () {
        this.setScrollPosition()
      }
    },
    created () {
      this.setOrgAndOrgComponentList()
      this.getFiscalYear()
    },
    beforeMount () {
      EventBus.$on('MINISTRY_DASHBOARD_NAMES', this.setMinistyDashboardNames)
    },
    mounted () {
      this.setPageTopMargin(0, 0)
    },
    methods: {
      setCurrentFiscalYear (fiscalYearList) {
        if (fiscalYearList.length === 0) {
            return
        }
        const currentDate = new Date()
        const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
        /** Define the year position  */
        const yearPosition = month < 7 ? 5 : 0
        const yearStr = `${year}`
        fiscalYearList.forEach(element => {
          if (element.text_en.indexOf(yearStr) === yearPosition) {
            this.currentFiscalYearId = element.value
            this.currentFiscalSortingId = element.sorting_order
          }
        })
      },
      fiscalYearBnAdd (data) {
        const bnList = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
        const bnAdded = data.map(item => {
          const fiscalYearData = {}
          const result = item.text_bn.split('').map(item => item === '-' ? '-' : bnList[item])
          fiscalYearData.text_bn = result.join('')
          return Object.assign({}, item, fiscalYearData)
        })
        return bnAdded
      },
      async getFiscalYear () {
        await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', {}).then(response => {
          if (response.success) {
            this.fiscalYearListData = this.fiscalYearBnAdd(response.data.fiscalYearList)
            this.setCurrentFiscalYear(this.fiscalYearListData)
          }
        })
      },
      setMinistyDashboardNames (menus) {
        const tmp = this.dashboardNames
        menus.map(item => {
          const urlLastSegment = item.link.split('/')[3]
          tmp[urlLastSegment] = item.name
        })
        this.dashboardNames = tmp
      },
      setScrollPosition () {
        this.$refs[this.$route.params.dashboard_name].scrollIntoView({ behavior: 'smooth', block: 'center' })
        // window.scrollBy(0, 100)
      },
      setPageTopMargin (newVal, oldVal) {
        let height = 145
        if (newVal !== oldVal) {
          height += newVal
        } else {
          height += this.defaultNavbarHeight
        }
        this.styleObject.scrollMarginTop = `-${height}px`
      },
      setOrgAndOrgComponentList () {
        RestApi.getData(commonServiceBaseUrl, 'common/org-and-org-component-list').then(response => {
          if (response.success === true) {
            const tmpData = response.data.orgList.map(item => {
              const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
              return Object.assign({}, item, tmp)
            })
            this.$store.commit('setOrgAndOrgComponentList', {
              orgList: tmpData,
              orgComponentList: response.data.orgComponentList
            })
          }
        })
      }
    }
  }
</script>
