<template>
    <b-container fluid>
      <iq-card>
        <template v-slot:body>
          <b-overlay>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset"  enctype="multipart/form-data">
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4">
                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        plain
                        v-model="search.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{ $t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4">
                    <ValidationProvider name="Production Season" vid="production_season_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="production_season_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('seedsConfig.productionSeason')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        plain
                        v-model="search.production_season_id"
                        :options="productionSeasonData"
                        id="production_season_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_type_id"
                      >
                      <template v-slot:label>
                      {{$t('ministryDashboard.cropType')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.crop_type_id"
                      :options="cropTypeData"
                      id="crop_type_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_sub_type_id"
                      >
                      <template v-slot:label>
                      {{$t('ministryDashboard.crop_sub_type')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.crop_sub_type_id"
                      :options="cropSubTypeData"
                      id="crop_sub_type_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_name_id"
                      >
                      <template v-slot:label>
                      {{$t('ministryDashboard.cropName')}}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.crop_name_id"
                      :options="cropNameData"
                      id="crop_name_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ dataLoading ? (currentLocale === 'en') ? 'Loading...': 'লোড হচ্ছে..'  : $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4">
                    <b-button class="float-left" type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-overlay>
        </template>
      </iq-card>
        <b-row v-show="showData">
          <b-col md="12">
            <b-overlay :show="loadingState">
                <b-row v-show="showData">
                  <b-col md="12">
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('ministryDashboard.cultivation_info_report_list') }}</h4>
                      </template>
                      <template v-slot:headerAction>
                      <b-button variant="primary" @click="pdfExport" class="mr-2">
                          {{  $t('globalTrans.export_pdf') }}
                      </b-button>
                      </template>
                      <template v-slot:body>
                        <b-overlay>
                      <b-row>
                              <!-- <b-col>
                                <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2" :orgList="orgList">
                                    {{ $t('ministryDashboard.cultivation_info_report_list')}}
                                  </list-report-head>
                              </b-col> -->
                            <b-col>
                              <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="seeds/config/report-heading/detail" :org-id="2" :orgList="orgList">
                                {{ $t('ministryDashboard.cultivation_info_report_list')}}
                              </list-report-head>
                            </b-col>
                            </b-row>
                              <b-col>
                            </b-col>
                              <b-overlay :show="loadingData">
                                <b-row mt-5>
                                  <b-table-simple bordered>
                                    <b-tr>
                                      <b-th class="text-center"> {{ $t('globalTrans.sl_no') }} </b-th>
                                      <b-th class="text-center"> {{ $t('ministryDashboard.cropName') }}</b-th>
                                      <b-th class="text-center"> {{ $t('seedsConfig.category') }}</b-th>
                                      <b-th class="text-center"> {{ $t('ministryDashboard.land_target') }}</b-th>
                                      <b-th class="text-center"> {{ $t('ministryDashboard.land_achievement') }}</b-th>
                                      <b-th class="text-center"> {{ $t('ministryDashboard.achievement_rate') }}</b-th>
                                    </b-tr>
                                    <template v-if="datas.length">
                                        <template v-for="(firstData, index) in datas" >
                                            <b-tr v-for="(info, index2) in firstData.details" :key="index + '_' +index2">
                                                <slot v-if="index2 === 0">
                                                    <b-td class="text-center align-middle" :rowspan="firstData.details.length" >{{ $n(index + 1) }}</b-td>
                                                    <b-td class="text-center align-middle" :rowspan="firstData.details.length" >
                                                        {{ currentLocale === 'en' ? info.crop_name : info.crop_name_bn }}
                                                    </b-td>
                                                </slot>
                                                <b-td class="text-center">{{ ($i18n.locale === 'bn') ? info.category_bn : info.category_en }}</b-td>
                                                <b-td class="text-center">{{ $n(info.land_target * 100000, { useGrouping: false }) }}</b-td>
                                                <b-td class="text-center">{{ $n(info.total_land_achievement * 100000, { useGrouping: false }) }} </b-td>
                                                <b-td class="text-center">{{ info.total_land_achievement && info.land_target >= 0 ? $n((info.total_land_achievement * 100000) / (info.land_target * 100000) * 100, { useGrouping: false }) : $n(0) }} </b-td>
                                            </b-tr>
                                        </template>
                                        <tr>
                                          <td class="text-center" colspan="3"> {{ $t('ministryDashboard.total')}}</td>
                                          <td class="text-center">{{ $n(grantTotalObj.land_target, { useGrouping: false }) }}</td>
                                          <td class="text-center">{{ $n(grantTotalObj.total_land_achievement, { useGrouping: false }) }}</td>
                                          <td class="text-center">{{ grantTotalObj.total_land_achievement && grantTotalObj.land_target >= 0 ? $n((grantTotalObj.total_land_achievement) / (grantTotalObj.land_target) * 100, { useGrouping: false }) : $n(0) }} </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <b-tr>
                                          <b-td colspan="6" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                        </b-tr>
                                    </template>
                                  </b-table-simple>
                                </b-row>
                            </b-overlay>
                        </b-overlay>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
            </b-overlay>
          </b-col>
        </b-row>
    </b-container>
  </template>
  <script>
  import { core } from '@/config/pluginInit'
  import RestApi, { seedFertilizerServiceBaseUrl, commonServiceBaseUrl, reportHeadingList } from '@/config/api_config'
  import { cultivateReport } from '../../../api/routes'
  import ListReportHead from '@/components/custom/ListReportHeadMinister.vue'
  // import ListReportHead from '@/Utils/report-head'
  import excel from 'vue-excel-export'
  import Vue from 'vue'
  import Pdf from './CultivationPdf'
  Vue.use(excel)
  export default {
    props: ['fiscalYearList', 'currentFiscalYearId'],
    name: 'UiDataTable',
    components: {
      ListReportHead
    },
    data () {
      return {
        showData: false,
        showHeading: false,
        search: {
          fiscal_year_id: 0,
          production_season_id: 0,
          crop_type_id: 0,
          crop_sub_type_id: 0,
          crop_name_id: 0
        },
        loadingData: false,
        datas: [],
        testId: 0,
        rows: [],
        seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
        reportHeadingList: reportHeadingList,
        CropTypeList: [],
        cropSubTypeList: [],
        cropNameList: [],
        productionSeasonList: [],
        orgList: [],
        loadingState: false,
        dataLoading: false,
        grantTotalObj: {
            land_target: 0,
            total_land_achievement: 0
        }
      }
    },
    created () {
      this.getCommonDropdownData()
      this.getOrgList()
      this.loadData()
      this.search.fiscal_year_id = this.currentFiscalYearId
    },
    computed: {
      cropTypeData: function () {
        const cropData = this.CropTypeList
        const tempData = cropData.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
      },
      productionSeasonData: function () {
        const seasons = this.productionSeasonList.filter(item => item.status === 1)
        const tmpData = seasons.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tmpData
      },
      cropSubTypeData: function () {
        const subTypes = this.cropSubTypeList
        const tmpData = subTypes.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tmpData
      },
      cropNameData: function () {
        const cropNames = this.cropNameList
        const tmpData = cropNames.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tmpData
      },
      currentLocale: function () {
        return this.$i18n.locale
      },
      loading () {
        return this.$store.state.commonObj.loading
      },
      listReload () {
        return this.$store.state.commonObj.listReload
      }
    },
    watch: {
      currentFiscalYearId: function (newVal, oldVal) {
        this.search.fiscal_year_id = newVal
      },
      loadingState: function (newVal) {
        if (newVal) {
          this.loadData()
        }
      },
      'search.crop_type_id': function (newVal, oldVal) {
        this.search.crop_name_id = 0
        this.search.crop_sub_type_id = 0
        if (newVal) {
          this.cropNameList = this.getCropName(newVal)
          this.cropSubTypeList = this.getCropSubType(newVal)
        }
      }
    },
    mounted () {
      core.index()
    },
    methods: {
      getColumnName (list, field, groupId, type = null) {
        const obj = list.find(item => item[field] === groupId)
        if (typeof obj !== 'undefined') {
          if (this.$i18n.locale === 'bn') {
            return obj.text_bn
          } else {
            return obj.text_en
          }
        } else {
          return ''
        }
      },
      getTotalLand (datas) {
        let totalLandAmount = 0
        let totalLandTarget = 0
        datas.forEach(item => {
          item.details.forEach(detail => {
            totalLandAmount += parseFloat(detail.land_target)
            totalLandTarget += parseFloat(detail.total_land_achievement)
          })
        })
        this.grantTotalObj.land_target = totalLandAmount * 100000
        this.grantTotalObj.total_land_achievement = totalLandTarget * 100000
      },
      searchClick () {
        this.loadData()
      },
      searchData () {
        this.loadData()
        this.showData = true
        this.showHeading = true
      },
      async loadData () {
        this.datas = []
        this.loadingData = true
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        const params = Object.assign({}, this.search)
        const result = await RestApi.getData(seedFertilizerServiceBaseUrl, cultivateReport, params)
          if (result.success) {
            this.datas = result.data
            this.getTotalLand(result.data)
          } else {
            this.datas = []
          }
        this.loadingData = false
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      async getOrgList () {
        await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', {}).then(response => {
          if (response.success) {
            this.orgList = response.data.orgList
          }
        })
      },
      async getCommonDropdownData () {
        this.dataLoading = true
        await RestApi.getData(seedFertilizerServiceBaseUrl, 'ministry-crop-dropdown', {}).then(response => {
          if (response.success) {
            this.CropTypeList = this.getCommonFormat(response.data.CropTypeList)
            this.CropNameList = this.getCommonFormat(response.data.CropNameList)
            this.seedSubTypeList = this.getCommonFormat(response.data.seedSubTypeList)
            this.productionSeasonList = this.getCommonFormat(response.data.productionSeasonList)
            this.seedCategoryList = this.getCommonFormat(response.data.seedCategoryList)
            this.loadData()
          }
        })
        this.dataLoading = false
      },
      getCommonFormat (data) {
        return data.map(item => {
          const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
        })
      },
      getCropSubType (id) {
        return this.seedSubTypeList.filter(item => item.status === 1 && item.crop_type_id === id)
      },
      getCropName (id) {
        return this.CropNameList.filter(item => item.status === 1 && item.crop_type_id === id)
      },
      pdfExport () {
        const reportTitle = this.$t('ministryDashboard.cultivation_info_report_list')
        Pdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', 2, reportTitle, this.datas, this, this.grantTotalObj, this.search)
      }
    }
  }
  </script>
  <style scoped>
   .container {
     display: flex;
     margin-bottom: 15px;
     justify-content: center;
   }
   .report-name {
     text-align: center;
   }
   .org-name {
    text-align: center;
   }
   .org-address {
     text-align: center;
   }
   .main-title {
     padding: 10px;
   }
   .project-name {
     text-align: center;
     font-weight: bold;
   }
  </style>
